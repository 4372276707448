/**
 *
 * Asynchronously loads the component for Templates
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Templates = lazyLoad(
  () => import('./index'),
  module => module.Templates,
);
