export const addMemberRolesList = ['Admin'];

export const deleteMemberRolesList = ['Admin'];

export const editMemberRolesList = ['Admin'];

export const editDocumentSettings = ['Admin'];

export const editCompanyPropertiesRolesList = ['Admin'];

export const editCompanySettingsRolesList = ['Admin'];

export const addCompanyContactsRolesList = ['Admin'];

export const deleteCompanyContactsRolesList = ['Admin'];

export const editCompanyContactsRolesList = ['Admin'];

export const isAuthorized = (userRole: string[], allowedRoles: string[]) => {
  return userRole.some(role => allowedRoles.includes(role));
};
