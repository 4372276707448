/**
 *
 * NewAccount
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNewAccountSlice } from './slice';
import { selectNewAccount } from './slice/selectors';
import { routes } from 'utils/routes';
import { Link } from 'react-router-dom';
import './styles.scss';
import { toast } from 'react-toast';
import { useQuery } from 'utils/commons';

interface Props {}

const planItems = [
  {
    item: 'Unlimited signatures',
  },
  {
    item: '3 Documents per month',
  },
  {
    item: '2 team members',
  },
  {
    item: 'Unlimited templates',
  },
];

export const NewAccount = memo((props: Props) => {
  const [emailId, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [appsumoCode, setAppsumoCode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isAppsumo, setIsAppsumo] = useState(false);
  const [isAppsumoEmail, setIsAppsumoEmail] = useState(false);
  const [isTOSchecked, setTOSchecked] = useState(false);

  const [ShowEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [showPasswordMissMatchError, setShowPasswordMissMatchError] = useState(
    false,
  );

  const params = useQuery();

  const toggleTOSChecked = () => {
    setTOSchecked(value => !value);
  };

  const [isPPchecked, setPPchecked] = useState(false);
  const togglePPChecked = () => {
    setPPchecked(value => !value);
  };
  const [userExists, setUserExists] = useState(false);
  const { actions } = useNewAccountSlice();
  const registerAccountState = useSelector(selectNewAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    const isAppSumo = params.get('appSumo');
    const queryEmail = params.get('email');
    
    if (isAppSumo) {
      setIsAppsumoEmail(true);
      setIsAppsumo(true);
    } else {
      setIsAppsumoEmail(false);
    }

    if (queryEmail) {
      setEmailId(queryEmail || '');
    }
  }, []);

  const onRegister = () => {
    if (password !== confirmPassword) {
      toast.error('Passwords does not match');
    } else {
      dispatch(
        actions.onRegister({
          req: {
            firstName: firstName,
            lastName: lastName,
            email: emailId,
            password: password,
            profileCompletion: false,
          },
          successCb: () => {
            if (isAppsumoEmail) {
              toast.success(
                'Account created successfully, thank you for purchasing ContractHub via AppSumo.',
              );
            }
          },
          // errorCB: () => {}
        }),
      );
    }
  };

const clearEmailExistError = () => {
  if (registerAccountState.emailAlreadyRegistered) {
    registerAccountState.emailAlreadyRegistered = false; 
  }
  console.log(registerAccountState.emailAlreadyRegistered)
}


  return (
    <div className="new-account-wrapper">
      <div className="new-account-contents w-100">
        <div
          className={`loginBox ${
            registerAccountState.stepNo === 1 ? 'text-align-center' : ''
          } `}
        >
          <div className="mobilePadder">
            {registerAccountState.stepNo === 0 || emailId === '' ? (
              <div className="logoIMG">
                <img
                  width={90}
                  src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/animated-logo-huge.gif"
                  alt=""
                />
              </div>
            ) : (
              <img
                width="100"
                className="autoMargin"
                src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/animated_emailNotification.gif"
                alt=""
              />
            )}

            {registerAccountState.stepNo === 0 || emailId === '' ? (
              <div className="d-flex flex-column justify-content-center start-page section">
                <div></div>
                <div className="title centerText">
                  {isAppsumo ? (
                    <>👋 Welcome Sumo-ling!</>
                  ) : (
                    <>ContractHub Registration</>
                  )}
                </div>
                <div className="subTitle">
                  Please create your account below to get started with
                  ContractHub.
                </div>
                <form
                  className="d-flex flex-column"
                  onSubmit={e => {
                    e.preventDefault();
                    onRegister();
                  }}
                >
                  <div className="transitionedInputWrapper full-input">
                    <label className="inp">
                      <input
                        required
                        value={emailId}
                        name="email"
                        id="username"
                        type="email"
                        autoComplete="off"
                        className={`
                          ${ShowEmailError || registerAccountState.emailAlreadyRegistered ? 'fieldError' : ''}
                          ${registerAccountState.emailAlreadyRegistered ? 'redText' : ''}
                        `}

                        onChange={e => {
                          setEmailId(e.target.value);
                          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                          if (!emailPattern.test(e.target.value)) {
                            setShowEmailError(true);
                          } else {
                            setShowEmailError(false);
                          }
                          dispatch(actions.onClearEmailAlreadyExists())
                        }}
                        placeholder="&nbsp;"
                        disabled={isAppsumoEmail}
                      />
                      <span className={`label 
                          ${registerAccountState.emailAlreadyRegistered && 'redText'}
                        `}>
                        <span style={{ color: 'red', paddingRight: '5px' }}>
                          *
                        </span>
                        Email
                      </span>
                      <span className="focus-bg"></span>
                    </label>

                    {registerAccountState.emailAlreadyRegistered && <small style={{color: "red", fontSize: '12px'}}>Email already registered</small> }

                  </div>
                  <div className="d-flex flex-row justify-content-space-between">
                    <div className="transitionedInputWrapper">
                      <label className="inp">
                        <input
                          required
                          value={firstName}
                          name="firstName"
                          id="firstName"
                          type="text"
                          className={showFirstNameError ? 'fieldError' : ''}
                          autoComplete="given-name"
                          onChange={e => {
                            setFirstName(e.target.value);
                            if (e.target.value.length === 0) {
                              setShowFirstNameError(true);
                            } else {
                              setShowFirstNameError(false);
                            }
                          }}
                          placeholder="&nbsp;"
                        />
                        <span className="label">
                          <span style={{ color: 'red', paddingRight: '5px' }}>
                            *
                          </span>
                          First Name
                        </span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>
                    <div className="transitionedInputWrapper">
                      <label className="inp">
                        <input
                          required
                          value={lastName}
                          name="lastName"
                          className={showLastNameError ? 'fieldError' : ''}
                          id="lastName"
                          type="text"
                          autoComplete="family-name"
                          onChange={e => {
                            setLastName(e.target.value);
                            if (e.target.value.length === 0) {
                              setShowLastNameError(true);
                            } else {
                              setShowLastNameError(false);
                            }
                          }}
                          placeholder="&nbsp;"
                        />
                        <span className="label">
                          <span style={{ color: 'red', paddingRight: '5px' }}>
                            *
                          </span>
                          Last Name
                        </span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>
                  </div>
                  <div className="transitionedInputWrapper full-input">
                    <label className="inp">
                      <input
                        required
                        className={showPasswordError ? 'fieldError' : ''}
                        value={password}
                        name="password"
                        id="password"
                        type="password"
                        autoComplete="off"
                        onChange={e => {
                          setPassword(e.target.value);
                          const passwordPattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
                          if (
                            e.target.value.length > 0 &&
                            !passwordPattern.test(e.target.value)
                          ) {
                            setShowPasswordError(true);
                          } else {
                            setShowPasswordError(false);
                          }
                        }}
                        placeholder="&nbsp;"
                      />
                      <span className="label">
                        <span style={{ color: 'red', paddingRight: '5px' }}>
                          *
                        </span>
                        Password
                      </span>
                      <span className="focus-bg"></span>
                    </label>
                  </div>
                  <small className="invalidInpuit-text greyText">
                    8-20 characters, 1 number, 1 letter, 1 symbol.
                  </small>
                  <div className="transitionedInputWrapper full-input">
                    <label className="inp">
                      <input
                        required
                        value={confirmPassword}
                        name="passwordConfirm"
                        id="passwordConfirm"
                        type="password"
                        className={showPasswordMissMatchError ? 'fieldError' : ''}
                        autoComplete="off"
                        onChange={e => {
                          setConfirmPassword(e.target.value)
                          if (e.target.value !== password) {
                            setShowPasswordMissMatchError(true);
                          } else {
                            setShowPasswordMissMatchError(false);
                          }
                        }
                      
                      }
                        placeholder="&nbsp;"
                      />
                      <span className="label">
                        <span style={{ color: 'red', paddingRight: '5px' }}>
                          *
                        </span>
                        Confirm Password
                      </span>
                      <span className="focus-bg"></span>
                    </label>
                    {showPasswordMissMatchError ? <small style={{color: 'red'}}>Passwords do not match</small> : null}
                  </div>
                  {/* isAppsumo */}{' '}
                  {/* {isAppsumo && (
                    <div className="transitionedInputWrapper full-input AppSumoCode">
                      <a target="_blank" href="https://appsumo.com/">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/a44a18b6-b1ce-4738-924b-d345a0c22239/as-logo-white.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20230223%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230223T045218Z&X-Amz-Expires=86400&X-Amz-Signature=6dd765b5d79f20c31604fd354722aa87d49d9eccf80cab7611955e8daab361f3&X-Amz-SignedHeaders=host&response-content-disposition=filename%3D%22as-logo-white.png%22&x-id=GetObject"
                          alt=""
                        />
                      </a>
                      <p>Lifetime access code</p>
                      <label className="inp">
                        <input
                          required
                          value={appsumoCode}
                          name="AppSumo Code"
                          id="appsumoCode"
                          type="text"
                          autoComplete="off"
                          // onChange={e => setConfirmPassword(e.target.value)}
                          placeholder="&nbsp;"
                        />
                        <span className="label">Enter Code</span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>
                  )} */}
                  <div className="transitionedInputWrapper">
                    {/* TOS  */}
                    <div className="subtitle mb-0 pb-0">
                      <input
                        type="checkbox"
                        className="mr-10"
                        checked={isTOSchecked}
                        onChange={toggleTOSChecked}
                      ></input>
                      <span style={{ color: 'red', paddingRight: '5px' }}>
                        *
                      </span>
                      I agree to the{' '}
                      <a
                        href="https://www.contracthub.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.contracthub.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Privacy Policy
                      </a>
                    </div>
                    {/* <small className='tiny danger'>You have to agree to the Terms of use & the privacy policy to continue </small> */}

                    <button
                      className={`full-width primaryButton large-btn bold mt-20 ' ${
                        registerAccountState.registering
                          ? 'roundSpinnerButton'
                          : ''
                      }`}
                      disabled={
                        registerAccountState.registering ||
                        !isTOSchecked ||
                        !emailId ||
                        !firstName ||
                        !lastName ||
                        !password ||
                        !confirmPassword
                      }
                      type="submit"
                    >
                      {registerAccountState.registering ? (
                        <i className="gg-spinner"></i>
                      ) : (
                        'Register'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center verify-mail-message section align-items-center">
                <div className="subtitle" style={{ width: '414px' }}>
                  <p>We just emailed a verification link to </p>
                  <code className="cursorNormal">{emailId}</code>
                  <p>
                    Please open the email you have received and follow the
                    instructions provided to complete the setup process for your
                    account.
                  </p>
                </div>
              </div>
            )}
            <Link
              to={routes.login}
              className="link-text normalLink mt-30 margin-auto width-fit-content"
            >
              Return to login
            </Link>

           <div className='mt-20'>©2024 <a href="https://www.contracthub.com" target='_blank'>ContractHub, Inc.</a> </div> All rights reserved


          </div>
        </div>
      </div>
      {/* <div className="plan-details d-flex flex-column justify-content-center align-items-center">
        <div className="plan-includes">Your plan includes</div>
        <div className="plan-items-list">
          {planItems.map((item, index) => (
            <div key={index} className="plan-item">
              <i className="gg-check-r check-icon"></i>
              <div className="item">{item.item}</div>
            </div>
          ))}
        </div>
        <div className="plus-more">Plus much more...</div>
      </div> */}
    </div>
  );
});
