import { call, put, takeLatest } from 'redux-saga/effects';
import api, { withAuthConfig } from 'utils/api';
import { urls } from 'utils/apiUrls';
import { singleContactActions as actions } from '.';
import { toast } from 'react-toast';
import { layoutActions } from 'app/components/LayoutWrapper/slice';

function* doGetSingleContact(data) {
  try {
    const res = yield call(
      api.get,
      urls.getContact(
        data.payload.companyId,
        data.payload.contactId,
        data.payload.contactType,
      ),
      withAuthConfig,
    );

    yield put(actions.getSingleContactSuccess(res.data));
  } catch (error) {
    toast.error('Something went wrong while fetching contact details.');
    yield put(actions.getSingleContactError(error));
  }
}

function* doUpdateSingleContact(data) {
  try {
    const res = yield call(
      api.put,
      urls.updateContact,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Contact updated successfully');

    if (data.payload.successCb) data.payload.successCb(res);

    yield put(actions.updateSingleContactSuccess(res.contact));
    yield put(layoutActions.setSelectedCompany({ company: res.company[0] }));
  } catch (error) {
    toast.error('Something went wrong while fetching contact details.');
    yield put(actions.updateSingleContactError(error));
  }
}

export function* singleContactSaga() {
  yield takeLatest(actions.getSingleContact.type, doGetSingleContact);
  yield takeLatest(actions.updateSingleContact.type, doUpdateSingleContact);
}
