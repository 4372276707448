import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { viewDocumentSaga } from './saga';
import { ViewDocumentState } from './types';

export const initialState: ViewDocumentState = {
  isGettingAgreement: false,
  getAgreementError: null,
  agreement: null,
  isGettingDocument: false,
  getDocumentError: null,
  document: null,
  isUpdatingDoc: false,
  updateDocError: null,
  isSubmittingDoc: false,
  isApprovingDoc: false,
  docApprovalError: null,
  isDecliningDoc: false,
  docDeclinalError: null,
  isSigningDoc: false,
  signDocError: null,
  isDeletingDoc: false,
  isUpdatingContact: false,
  updateContactError: null,
  contactInfo: null,
  isUserExists: false,
  guestData: null,
  isCheckingGuestAgreementUser: false,
  isResendingDocument: false,
  resendDocumentError: null,
  isRescindingDocument: false,
  rescindDocumentError: null,
  isImportingToCompany: false,
  importToCompanyError: null,
  isChangingRecipient: false,
  changeRecipientError: null,
  isGeneratingDigitalSignatureCertificate: false,
  generateDigitalSignatureCertificateError: null,
  isUpdatingDocumentPdf: false,
  updatingPdfError: null,
};

const slice = createSlice({
  name: 'viewDocument',
  initialState,
  reducers: {
    resetAgreementState(state) {
      state.agreement = null;
      state.document = null;
      state.contactInfo = null;
    },
    getAgreement(state, action: PayloadAction<any>) {
      state.getAgreementError = null;
      state.isGettingAgreement = true;
    },
    getAgreementSuccess(state, action: PayloadAction<any>) {
      state.isGettingAgreement = false;
      state.agreement = action.payload.agreement;
      state.contactInfo = action.payload.contactInfo;
    },
    getAgreementError(state, action: PayloadAction<any>) {
      state.getAgreementError = action.payload.message;
      state.isGettingAgreement = false;
    },
    getDocument(state, action: PayloadAction<any>) {
      state.getDocumentError = null;
      state.isGettingDocument = true;
    },
    getDocumentSuccess(state, action: PayloadAction<any>) {
      state.isGettingDocument = false;
      state.document = action.payload.document;
    },
    getDocumentError(state, action: PayloadAction<any>) {
      state.getDocumentError = action.payload.message;
      state.isGettingDocument = false;
    },
    onAgreementMetaChange(state, action: PayloadAction<any>) {
      state.agreement.agreement[action.payload.fieldName] =
        action.payload.value;
    },
    onAgreementFolderChange(state, action: PayloadAction<any>) {
      state.agreement.folderId = action.payload;
    },
    updateDocument(state, action: PayloadAction<any>) {
      state.updateDocError = null;

      if (action.payload.type === 'save') state.isUpdatingDoc = true;
      else if (action.payload.type === 'submit') state.isSubmittingDoc = true;
      else if (action.payload.type === 'delete') state.isDeletingDoc = true;
    },

    updateDocumentSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingDoc = false;
      state.isSubmittingDoc = false;
      state.isDeletingDoc = false;
      state.agreement = action.payload.data;
    },

    updateDocumentError(state, action: PayloadAction<any>) {
      state.isUpdatingDoc = false;
      state.isSubmittingDoc = false;
      state.isDeletingDoc = false;
      state.updateDocError = action.payload?.message || 'Something went wrong';
    },

    approveDocument(state, action: PayloadAction<any>) {
      state.docApprovalError = null;
      state.isApprovingDoc = true;
    },
    approveDocumentSuccess(state, action: PayloadAction<any>) {
      state.isApprovingDoc = false;
      const approverIndex = state.agreement.approvers.findIndex(
        approver => approver.memberId === action.payload.userId,
      );
      state.agreement.approvers[approverIndex].status = 'approved';
    },
    approveDocumentError(state, action: PayloadAction<any>) {
      state.isApprovingDoc = false;
      state.docApprovalError = action.payload;
    },
    declineDocument(state, action: PayloadAction<any>) {
      state.docDeclinalError = null;
      state.isDecliningDoc = true;
    },
    declineDocumentSuccess(state, action: PayloadAction<any>) {
      state.isDecliningDoc = false;
      const declinerIndex = state.agreement.decliners.findIndex(
        decliner => decliner.id === action.payload.userId,
      );
      state.agreement.approvers[declinerIndex].status = 'declined';
    },
    declineDocumentError(state, action: PayloadAction<any>) {
      state.isDecliningDoc = false;
      state.docDeclinalError = action.payload;
    },

    signAgreement(state, action: PayloadAction<any>) {
      state.signDocError = null;
      state.isSigningDoc = true;
    },
    signAgreementSuccess(state, action: PayloadAction<any>) {
      state.isSigningDoc = false;

      if (action.payload?._id) state.agreement = action.payload;
    },
    signAgreementError(state, action: PayloadAction<any>) {
      state.isSigningDoc = false;
      state.signDocError = action.payload;
    },

    updateContactInfo(state, action: PayloadAction<any>) {
      state.updateContactError = null;
      state.isUpdatingContact = true;
    },
    updateContactInfoSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;

      const { updatedContactInfo } = action.payload;
      state.contactInfo = updatedContactInfo;
    },
    updateContactInfoError(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;
      state.updateContactError = action.payload;
    },

    checkGuestAgreementUser(state, action: PayloadAction<any>) {
      // state.updateContactError = null;
      state.isCheckingGuestAgreementUser = true;
    },
    checkGuestAgreementUserSuccess(state, action: PayloadAction<any>) {
      state.isCheckingGuestAgreementUser = false;
      state.guestData = action.payload;
      state.isUserExists = action.payload.user ? true : false;
    },
    checkGuestAgreementUserError(state, action: PayloadAction<any>) {
      state.isCheckingGuestAgreementUser = false;
      // state.updateContactError = action.payload;
    },
    confirmAgreementContact(state, action: PayloadAction<any>) {
      state.updateDocError = null;
      state.isUpdatingContact = true;
    },
    confirmAgreementContactSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;
      // state.contactInfo = action.payload;
    },
    confirmAgreementContactError(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;
      state.updateDocError = action.payload;
    },
    resendDocument(state, action: PayloadAction<any>) {
      state.resendDocumentError = null;
      state.isResendingDocument = true;
    },
    resendDocumentSuccess(state, action: PayloadAction<any>) {
      state.isResendingDocument = false;
    },
    resendDocumentError(state, action: PayloadAction<any>) {
      state.isResendingDocument = false;
      state.resendDocumentError = action.payload;
    },
    rescindDocument(state, action: PayloadAction<any>) {
      state.rescindDocumentError = null;
      state.isRescindingDocument = true;
    },
    rescindDocumentSuccess(state, action: PayloadAction<any>) {
      state.isRescindingDocument = false;
      state.agreement = action.payload.agreement;
    },
    rescindDocumentError(state, action: PayloadAction<any>) {
      state.isRescindingDocument = false;
      state.rescindDocumentError = action.payload;
    },
    importToCompany(state, action: PayloadAction<any>) {
      state.importToCompanyError = null;
      state.isImportingToCompany = true;
    },
    importToCompanySuccess(state, action: PayloadAction<any>) {
      state.isImportingToCompany = false;
      // state.agreement = action.payload.agreement;
    },
    importToCompanyError(state, action: PayloadAction<any>) {
      state.isImportingToCompany = false;
      state.importToCompanyError = action.payload;
    },

    changeRecipient(state, action: PayloadAction<any>) {
      state.changeRecipientError = null;
      state.isChangingRecipient = true;
    },
    changeRecipientSuccess(state, action: PayloadAction<any>) {
      state.isChangingRecipient = false;
      // state.agreement = action.payload;
    },
    changeRecipientError(state, action: PayloadAction<any>) {
      state.isChangingRecipient = false;
      state.changeRecipientError = action.payload;
    },

    generateDigitalSignatureCertificate(state, action: PayloadAction<any>) {
      state.generateDigitalSignatureCertificateError = null;
      state.isGeneratingDigitalSignatureCertificate = true;
    },
    generateDigitalSignatureCertificateSuccess(
      state,
      action: PayloadAction<any>,
    ) {
      state.isGeneratingDigitalSignatureCertificate = false;
      // state.agreement = action.payload;
    },
    generateDigitalSignatureCertificateError(
      state,
      action: PayloadAction<any>,
    ) {
      state.isGeneratingDigitalSignatureCertificate = false;
      state.generateDigitalSignatureCertificateError = action.payload;
    },

    updateDocumentPdf(state, action: PayloadAction<any>) {
      state.updatingPdfError = null;
      state.isUpdatingDocumentPdf = true;
    },
    updateDocumentPdfSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingDocumentPdf = false;
      state.agreement = action.payload.data;
    },
    updateDocumentPdfError(state, action: PayloadAction<any>) {
      state.isUpdatingDocumentPdf = false;
      state.updatingPdfError = action.payload;
    },
  },
});

export const { actions: viewDocumentActions } = slice;

export const useViewDocumentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: viewDocumentSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useViewDocumentSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
