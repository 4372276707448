/**
 *
 * Asynchronously loads the component for SetGuestPassword
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SetGuestPassword = lazyLoad(
  () => import('./index'),
  module => module.SetGuestPassword,
);
