/**
 *
 * Asynchronously loads the component for Integrated
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Integrated = lazyLoad(
  () => import('./index'),
  module => module.Integrated,
);
