import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { individualDashboardSaga } from './saga';
import { IndividualDashboardState } from './types';

export const initialState: IndividualDashboardState = {
  isGettingUserDocuments: false,
  documents: [],
  getDocumentError: null,
  allDocuments: [],
};

const slice = createSlice({
  name: 'individualDashboard',
  initialState,
  reducers: {
    getDocuments(state, action: PayloadAction<any>) {
      state.isGettingUserDocuments = true;
      state.getDocumentError = null;
    },
    getDocumentsSuccess(state, action: PayloadAction<any>) {
      state.isGettingUserDocuments = false;
      state.documents = action.payload.folderAgreements;
      state.allDocuments = action.payload.allAgreements;
    },
    getDocumentsError(state, action: PayloadAction<any>) {
      state.isGettingUserDocuments = false;
      state.getDocumentError = action.payload.message;
    },
    changeSelectedFolderDocuments(state, action: PayloadAction<any>) {
      state.documents = state.allDocuments[action.payload];
    },
  },
});

export const { actions: individualDashboardActions } = slice;

export const useIndividualDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: individualDashboardSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useIndividualDashboardSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
