import { call, put, takeLatest } from 'redux-saga/effects';
import api, { withAuthConfig } from 'utils/api';
import { urls } from 'utils/apiUrls';
import { layoutActions as actions } from '.';
import { toast } from 'react-toast';
import { clearSession } from 'utils/auth';

function* doGetUser(data) {
  try {
    const res = yield call(
      api.get,
      urls.getUser + data.payload.userId,
      withAuthConfig,
    );

    yield put(actions.getUserSuccess(res.user));
  } catch (error) {
    yield put(actions.getUserError(error));
  }
}

function* doGetCompanies(data) {
  try {
    const res = yield call(
      api.get,
      urls.getAccounts + data.payload.userId,
      withAuthConfig,
    );

    if (data.payload.successCb) data.payload.successCb(res); //hardcoded id

    yield put(actions.getCompaniesSuccess(res));
  } catch (error) {
    toast.error('Something went wrong while fetching companies');
    yield put(actions.getCompaniesError(error));
  }
}

function* doUpdateSelectedCompany(data) {
  try {
    const { cId, uId } = data.payload;
    const res = yield call(api.get, urls.viewCompany(cId, uId), withAuthConfig);

    yield put(actions.updateSelectedCompanySuccess(res));
  } catch (error) {
    toast.error('Something went wrong while fetching company');
    yield put(actions.updateSelectedCompanyError(error));
  }
}

function* doLogout() {
  try {
    const res = yield call(api.get, urls.logout, withAuthConfig);

    yield put(actions.logOutSuccess(res));
  } catch (error) {
    yield put(actions.logOutError(error));
  }
  clearSession();
  window.location.href = '/';
}

function* doUploadDoc(data) {
  try {
    const res = yield call(api.post, urls.importDocument, data.payload.form, {
      ...withAuthConfig,
      headers: {
        ...withAuthConfig.headers,
        contentType: 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        console.log(progressEvent.loaded);

        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        data.payload.setProgress(percent);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
      },
    });

    yield put(actions.uploadDocSuccess(res));
    data.payload.successCb(res.document.agrUID);
    // toast.success('Document uploaded successfully');
  } catch (error) {
    yield put(actions.uploadDocError(error));
  }
}

function* doUploadTemplate(data) {
  try {
    const res = yield call(api.post, urls.uploadTemplate, data.payload.form, {
      ...withAuthConfig,
      headers: {
        ...withAuthConfig.headers,
        contentType: 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        console.log(progressEvent.loaded);

        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        data.payload.setProgress(percent);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
      },
    });

    yield put(actions.uploadTemplateSuccess(res.template));
    data.payload.successCb(res.template._id); //hardcoded id
    toast.success('Template uploaded successfully');
  } catch (error) {
    data.payload.errorCb(); //hardcoded id
    toast.error(error?.message || 'Something went wrong');
    yield put(
      actions.uploadTemplateError(error?.message || 'Something went wrong'),
    );
  }
}

function* doCreateFolder(data) {
  const folder = data.payload.folder;
  try {
    const res = yield call(api.post, urls.createFolder, folder, withAuthConfig);

    yield put(actions.createFolderSuccess(res));
    if (data.payload.successCb) data.payload.successCb();
    toast.success('Folder created successfully');
  } catch (error) {
    toast.error(
      error?.error?.message || 'Something went wrong while creating folder',
    );
    yield put(actions.createFolderError(error?.error));
  }
}

function* doAcceptCompanyInvitation(data) {
  try {
    const res = yield call(
      api.post,
      urls.acceptCompanyInvitation,
      data.payload,
      withAuthConfig,
    );

    if (res.statusCode === 200) {
      yield put(
        actions.acceptCompanyInviteSuccess({
          res,
          taskId: data.payload.taskId,
        }),
      );
      if (data.payload.successCb) data.payload.successCb();
      window.location.reload();
      toast.success('Invitation accepted successfully');
    } else {
      yield put(
        actions.acceptCompanyInviteSuccess({
          res,
          taskId: data.payload.taskId,
        }),
      );
      toast.warn('Task already finished');
    }
  } catch (error) {
    toast.error(
      error?.error?.message ||
        'Something went wrong while accepting invitation',
    );
    yield put(actions.acceptCompanyInviteError(error?.error));
  }
}

export function* layoutSaga() {
  yield takeLatest(actions.getUser.type, doGetUser);
  yield takeLatest(actions.getCompanies.type, doGetCompanies);
  yield takeLatest(actions.updateSelectedCompany.type, doUpdateSelectedCompany);
  yield takeLatest(actions.logOut.type, doLogout);
  yield takeLatest(actions.uploadDoc.type, doUploadDoc);
  yield takeLatest(actions.uploadTemplate.type, doUploadTemplate);
  yield takeLatest(actions.createFolder.type, doCreateFolder);
  yield takeLatest(actions.acceptCompanyInvite.type, doAcceptCompanyInvitation);
}
