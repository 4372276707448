/**
 *
 * Asynchronously loads the component for CompleteAccount
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CompleteAccount = lazyLoad(
  () => import('./index'),
  module => module.CompleteAccount,
);
