/**
 *
 * Asynchronously loads the component for GuestCompanyAccount
 *
 */

import { lazyLoad } from 'utils/loadable';

export const GuestChoseCompany = lazyLoad(
  () => import('./index'),
  module => module.GuestChoseCompany,
);
