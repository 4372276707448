export const routes = {
  login: '/login',
  directLoginLink: (userName?, password?) =>
    userName && password
      ? `/login/sandbox/${userName}/${password}/`
      : `/login/sandbox/:userName/:password`,
  newAccount: '/new-account',
  createCompany: '/create-company',
  forgotPassword: '/forgotPassword/verifyPin',
  guestWelcome: (docId?, accessKey?) =>
    docId && accessKey
      ? `/guest/welcome/${docId}/${accessKey}`
      : '/guest/welcome/:docId/:accessKey',
  guestIndividualAccount: (docId?, accessKey?) =>
    docId && accessKey
      ? `/guest/individual/${docId}/${accessKey}`
      : '/guest/individual/:docId/:accessKey',
  guestChoseCompany: (docId?, accessKey?) =>
    docId && accessKey
      ? `/guest/company/${docId}/${accessKey}`
      : '/guest/company/:docId/:accessKey',
  guestLinkExpired: (docId?, accessKey?) =>
    docId && accessKey
      ? `/guest/link-expired/${docId}/${accessKey}`
      : '/guest/link-expired/:docId/:accessKey',

  setPassword: (uid?, cid?) =>
    uid && cid
      ? `/setpassword/${uid}/${cid}/new`
      : `/setpassword/:uId/:cId/new`,
  setGuestPassword: (connectKey?, companyContactType?) =>
    connectKey && companyContactType
      ? `/setNewPassword/draft/${connectKey}/${companyContactType}`
      : `/setNewPassword/draft/:connectKey/:companyContactType`,

  verifyAccount: (code?) =>
    code ? `/verify/${code}` : `/verify/:verfificationCode`,
  completeAccount: (userId?) =>
    userId ? `/complete-account/${userId}` : `/complete-account/:userId`,

  dashboard: `/dashboard`,
  myAccount: `/myAccount`,
  contacts: (id?) =>
    id ? `/company/${id}/contacts` : '/company/:companyId/contacts',
  contact: (cId?, contactId?, contactType?) =>
    cId
      ? `/company/${cId}/contacts/${contactType || 'individual'}/${contactId}`
      : '/company/:companyId/contacts/:contactType/:contactId',
  companyDashboard: (id?) =>
    id ? `/company/${id}/dashboard` : '/company/:companyId/dashboard',
  newContact: (id?) =>
    id ? `/company/${id}/contact/new` : '/company/:companyId/contact/new',
  companySettings: (id?) =>
    id ? `/company/${id}/settings` : '/company/:companyId/settings',

  viewCompanyDocument: (cId?, docId?) =>
    cId
      ? `/company/${cId}/document/${docId}`
      : '/:viewParty/:companyId/document/:docId',
  viewIndividualDocument: (cId?, docId?) =>
    cId
      ? `/individual/${cId}/document/${docId}`
      : '/:viewParty/:companyId/document/:docId',
  guestViewDocument: (docId?, tempCode?) =>
    docId
      ? `/guest/viewDocument/${docId}/${tempCode}`
      : '/:viewParty/viewDocument/:docId/:tempCode',
  viewSelfDocument: (docId?) =>
    docId ? `/document/${docId}` : '/document/:docId',

  viewTemplates: (id?) =>
    id ? `/company/${id}/templates` : '/company/:companyId/templates',
  newTemplate: (id?, templateId?) =>
    id
      ? `/company/${id}/newTemplate/${templateId}`
      : '/company/:companyId/newTemplate/:templateId',
  viewTemplate: (cId?, tId?) =>
    cId
      ? `/company/${cId}/template/${tId}`
      : '/company/:companyId/template/:templateId',
  generateDocFromLink: `/template/generateLink`,

  integrated: (appName?) => (appName ? `/oauth/${appName}` : `/oauth/:appName`),
};
