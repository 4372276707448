/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * SingleContactPage
 *
 */
import React, { useEffect, useState } from 'react';
import { LayoutWrapper } from 'app/components/LayoutWrapper/Loadable';
import { useLayoutSlice } from 'app/components/LayoutWrapper/slice';
import { selectLayout } from 'app/components/LayoutWrapper/slice/selectors';
import { Helmet } from 'react-helmet-async';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { routes } from 'utils/routes';
import { useSingleContactSlice } from './slice';
import { selectSingleContact } from './slice/selectors';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './styles.scss';
import { Link } from 'react-router-dom';
import { CompanyPoc } from './CompanyPoc/Loadable';
import { ObjectID } from 'bson';
import {
  editCompanyContactsRolesList,
  isAuthorized,
} from 'utils/roleaccesslist';

interface Props {
  isModularView?: boolean;
  onCancel?: Function;
  contactId?: string;
  contactType?: string;
  isOnlyContactsView?: boolean;
  onUpdateSuccess?: Function;
}

type POC = {
  defaultSigner: boolean;
  email: string;
  name: string;
  title: string;
  _id: any;
};

type UserProperty = {
  propertyKey: string;
  propertyValue: string;
};

const defaultPOC: POC = {
  defaultSigner: false,
  email: '',
  name: '',
  title: '',
  _id: new ObjectID(),
};

const tableStyle = {
  rows: {
    style: {
      minHeight: '99px',
      border: '1px solid var(--sidebar-border) !important',
      marginTop: '-1px',
      ':hover': {
        // transform: 'scale(1.002)',
      },
    },
  },
  headRow: {
    style: {
      borderBottomSize: '1px',
      borderColor: '#E8EEF7',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

export function SingleContactPage(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterText, setFilterText] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortBy, setSortBy] = useState('newest');

  const [companyPOCList, _setCompanyPOCList] = useState<POC[]>([]);
  const [userPropertiesList, _setUserPropertiesList] = useState<UserProperty[]>(
    [],
  );
  const [isMounted, setIsMounted] = useState(false);

  const { actions } = useSingleContactSlice();
  useLayoutSlice();
  const singleContactState = useSelector(selectSingleContact);
  const layoutState = useSelector(selectLayout);

  const dispatch = useDispatch();

  const { companyId, contactId, contactType } = useParams<any>();

  useEffect(() => {
    const cId = contactId || props.contactId;
    const cType = contactType || props.contactType;

    dispatch(actions.resetForm());
    dispatch(actions.resetContact());
    dispatch(
      actions.getSingleContact({
        companyId,
        contactId: cId,
        contactType: cType,
      }),
    );
    return () => {
      dispatch(actions.resetContact());
    };
  }, [companyId, contactId, contactType, props.contactId, props.contactType]);

  useEffect(() => {
    if (singleContactState.contact?.contactType === 'company') {
      _setCompanyPOCList(singleContactState.contact?.companySigners || []);
      setIsMounted(true);
    }
  }, [singleContactState.contact, singleContactState.contact?.companySigners]);

  useEffect(() => {
    if (singleContactState.contact?.contactType === 'individual') {
      _setUserPropertiesList(singleContactState.contact?.userProperties || []);
      setIsMounted(true);
    }
  }, [singleContactState.contact, singleContactState.contact?.userProperties]);

  useEffect(() => {
    if (singleContactState.contactError)
      toast.error(singleContactState.contactError);
  }, [singleContactState.contactError]);

  const changeFieldValue = (fieldName, value) => {
    dispatch(
      actions.changeFieldValue({
        fieldName,
        value,
      }),
    );
  };

  const onUpdateContact = () => {
    if (contactType === 'company' || props.contactType === 'company') {
      const invalidEmail = singleContactState.contact.companySigners.find(
        poc => !/\S+@\S+\.\S+/.test(poc.email),
      );

      if (invalidEmail) {
        toast.error('Please check if all signer email ids are valid');
        return;
      }
    }
    dispatch(
      actions.updateSingleContact({
        request: {
          companyId: layoutState.selectedCompany?._id,
          ...singleContactState.contact,
          name: singleContactState.fields.name,
          email: singleContactState.fields.email,
          phone: singleContactState.fields.phone,
          legalAddress: {
            ...singleContactState.fields,
          },
          ...((contactType === 'company' ||
            props.contactType === 'company') && {
            companyName: singleContactState.fields.name,
            noticeEmail: singleContactState.fields.email,
          }),
        },
        successCb: data => {
          if (props.onUpdateSuccess) props.onUpdateSuccess(data);
        },
      }),
    );
  };

  const filteredAgreements = singleContactState.contactAgreements
    ?.slice()
    .sort((agr1, agr2) => {
      const firstDate = new Date(agr1.dates.draftDate).getTime();
      const secondDate = new Date(agr2.dates.draftDate).getTime();

      return sortBy === 'oldest'
        ? firstDate - secondDate
        : secondDate - firstDate;
    })
    .filter(agreement =>
      agreement
        ? agreement.agreement.agreementTitle
            .split(' ')
            .map(name => name.toLowerCase())
            .findIndex(name =>
              name.toLowerCase().startsWith(filterText.toLowerCase()),
            ) > -1
        : agreement.drafter.userName
            .split(' ')
            .map(name => name.toLowerCase())
            .findIndex(name =>
              name.toLowerCase().startsWith(filterText.toLowerCase()),
            ) > -1,
    );

  const setCompanyPOCList = pocList => {
    dispatch(actions.updateContactPOCList(pocList));
  };

  const setUserPropertiesList = propsList => {
    dispatch(actions.updateContactUserPropertiesList(propsList));
  };

  const onAddNewPOC = () => {
    if (companyPOCList.filter(poc => !poc.email || !poc.name).length <= 0) {
      setCompanyPOCList([
        ...companyPOCList,
        { ...defaultPOC, _id: new ObjectID() },
      ]);
    } else {
      toast.error(
        'Please remove signer with empty name or email before adding new',
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAddNewUserProperty = () => {
    if (
      userPropertiesList.filter(
        property => !property.propertyKey || !property.propertyValue,
      ).length <= 0
    ) {
      setUserPropertiesList([
        ...userPropertiesList,
        { propertyKey: '', propertyValue: '' },
      ]);
    } else {
      toast.error(
        'Please remove property with empty key or value before adding new',
      );
    }
  };

  const onPOCValueChange = (index, field, value) => {
    const tempPOCList = companyPOCList.map((poc, i) => {
      if (i === index)
        return {
          ...poc,
          [field]: value,
        };
      else
        return field === 'defaultSigner'
          ? { ...poc, defaultSigner: false }
          : poc;
    });

    setCompanyPOCList(tempPOCList);
  };

  // const onUserPropertyValueChange = (index, field, value) => {
  //   const tempUserPropertiesList = userPropertiesList.map((property, i) => {
  //     if (i === index)
  //       return {
  //         ...property,
  //         [field]: value,
  //       };
  //     else return property;
  //   });

  //   setUserPropertiesList(tempUserPropertiesList);
  // };

  const onRemovePOC = id => {
    setCompanyPOCList(companyPOCList.filter(c => c._id !== id));
  };

  // const onRemoveUserProperty = key => {
  //   setUserPropertiesList(
  //     userPropertiesList.filter(c => c.propertyKey !== key),
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="ContractHub homepage" />
      </Helmet>

      <LayoutWrapper
        title={'Contacts'}
        subTitle={
          singleContactState.contact?.name ||
          singleContactState.contact?.companyName ||
          ''
        }
        extraButtons={[
          {
            type: 'primary',
            text: 'Save',
            disabled:
              singleContactState.isUpdatingContact ||
              !isAuthorized(
                layoutState.userRoles,
                editCompanyContactsRolesList,
              ),
            onClick: () => {
              isAuthorized(layoutState.userRoles, editCompanyContactsRolesList)
                ? //       history.push(routes.newContact(layoutState.selectedCompany?._id));
                  onUpdateContact()
                : toast.error('You are not authorized to edit this contact');
            },
          },
          ...(props.isModularView
            ? [
                {
                  type: 'outline',
                  text: 'Cancel',
                  onClick: () => {
                    if (props.onCancel) props.onCancel();
                  },
                  disabled: singleContactState.isUpdatingContact,
                },
              ]
            : []),
        ]}
        isModularView={props.isModularView}
      >
        {isMounted ? (
          <div className="view-with-sidebar contactView">
            <Tabs>
              <div className="innerSideBar">
                <div className="viewSearchBar_wrapper col-2 p-0">
                  <TabList>
                    <Tab>
                      <div
                        style={{
                          ...(contactType !== 'company' &&
                            props.contactType !== 'company' && {
                              borderBottom: '1px solid #e8e8e8',
                            }),
                          padding: '10px 0px',
                        }}
                      >
                        <label>Data Fields</label>
                      </div>
                    </Tab>
                    {/* {contactType !== 'company' && (
                    <Tab>
                    </Tab>
                  )} */}
                    {(contactType === 'company' ||
                      props.contactType === 'company') && (
                      <Tab>
                        <div
                          style={{
                            // borderBottom: '0px solid #e8e8e8',
                            padding: '10px 0px',
                          }}
                        >
                          <label>Authorized Signers</label>
                        </div>
                      </Tab>
                    )}
                  </TabList>
                </div>

                <div className="dataFields-form h-100 overflow-y">
                  <TabPanel>
                    <form
                      className="shadowForm"
                      onSubmit={e => {
                        e.preventDefault();
                        onUpdateContact();
                      }}
                    >
                      <div className="d-flex flex-column ch-form-item">
                        {/* Legal Name*/}
                        <div className="transitionedInputWrapper">
                          <label className="inp">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              value={singleContactState.fields.name}
                              type="text"
                              id="inp-legal-name"
                              onChange={e =>
                                changeFieldValue('name', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Signer Name</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* Email */}
                        <div className="transitionedInputWrapper">
                          <label className="inp">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              value={singleContactState.fields.email}
                              type="text"
                              id={'inp-email-'}
                              onChange={e =>
                                changeFieldValue('email', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Email</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* Phone */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              value={singleContactState.fields.phone}
                              type="text"
                              id={'inp-phone'}
                              onChange={e =>
                                changeFieldValue('phone', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Phone</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* Street address line 1 */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              value={singleContactState.fields.street}
                              type="text"
                              id={'inp-street'}
                              onChange={e =>
                                changeFieldValue('street', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Street address line 1</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* Street address line 2 */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              type="text"
                              id={'inp-street2'}
                              value={singleContactState.fields.street2}
                              onChange={e =>
                                changeFieldValue('street2', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Street address line 1</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* State */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              type="text"
                              id={'inp-state'}
                              value={singleContactState.fields.state}
                              onChange={e =>
                                changeFieldValue('state', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">State</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* ZIP */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              type="text"
                              id={'inp-zipCode'}
                              value={singleContactState.fields.zipCode}
                              onChange={e =>
                                changeFieldValue('zipCode', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">ZIP</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>

                        {/* COUNTRY */}
                        <div className="transitionedInputWrapper">
                          <label className="inp mt-10">
                            <input
                              disabled={
                                !isAuthorized(
                                  layoutState.userRoles,
                                  editCompanyContactsRolesList,
                                )
                              }
                              required
                              type="text"
                              id="inp"
                              value={singleContactState.fields.country}
                              onChange={e =>
                                changeFieldValue('country', e.target.value)
                              }
                              placeholder="&nbsp;"
                            />
                            <span className="label">Country</span>
                            <span className="focus-bg"></span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </TabPanel>
                  {/* {contactType !== 'company' && (
                  <TabPanel>
                    <div className="d-flex mb-20 flex-col">
                      <div className="d-flex mb-20  flex-col">
                        <label>User Properties</label>
                      </div>

                      {userPropertiesList?.map((property, i) => (
                        <UserProperties
                          key={i}
                          propertyKey={property.propertyKey}
                          propertyValue={property.propertyValue}
                          onRemoveUserProperty={onRemoveUserProperty}
                          onValueChange={(field, value) => {
                            onUserPropertyValueChange(i, field, value);
                          }}
                        />
                      ))}

                      <button
                        type="button"
                        className="blueButton"
                        onClick={onAddNewUserProperty}
                      >
                        Add Another
                      </button>
                    </div>
                  </TabPanel>
                )} */}
                  {(contactType === 'company' ||
                    props.contactType === 'company') && (
                    <TabPanel>
                      <div className="d-flex mb-20 flex-col">
                        {companyPOCList?.map((signer, i) => (
                          <CompanyPoc
                            key={signer._id}
                            id={signer._id}
                            name={signer.name}
                            email={signer.email}
                            title={signer.title}
                            defaultSigner={signer.defaultSigner}
                            onValueChange={(field, value) =>
                              onPOCValueChange(i, field, value)
                            }
                            onRemovePOC={onRemovePOC}
                          />
                        ))}

                        <button
                          type="button"
                          className="primaryButton"
                          onClick={onAddNewPOC}
                        >
                          Add Another
                        </button>
                      </div>
                    </TabPanel>
                  )}
                </div>
              </div>
            </Tabs>
            {!props.isOnlyContactsView && (
              <div className="view">
                {/* <div className="viewSearchBar_wrapper">
              <div className="viewSearchBar_wrapper-searchInput">
            
            
                <i className="gg-search mr-10"></i>
                <input
                disabled={!isAuthorized(layoutState.userRoles, editCompanyContactsRolesList)}
                  type="text"
                  placeholder="Type here to search documents"
                />
              </div>
            </div> */}
                <>
                  <div className="viewSearchBar_wrapper">
                    <div className="viewSearchBar_wrapper-searchInput">
                      <div className="search-wrapper">
                        <input
                          disabled={
                            !isAuthorized(
                              layoutState.userRoles,
                              editCompanyContactsRolesList,
                            )
                          }
                          className="search-input"
                          type="text"
                          value={filterText}
                          onChange={e => setFilterText(e.target.value)}
                          placeholder="Search documents by name, party, or email"
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="feather feather-search"
                          viewBox="0 0 24 24"
                        >
                          <defs></defs>
                          <circle cx="11" cy="11" r="8"></circle>
                          <path d="M21 21l-4.35-4.35"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="pagePadder pt-20 overflow-y">
                    {filteredAgreements.length > 0 && (
                      <DataTable
                        pagination
                        selectableRows
                        columns={[
                          {
                            name: 'Title',
                            sortable: false,
                            grow: 2,
                            cell: row => (
                              <Link
                                to={routes.viewCompanyDocument(
                                  layoutState.selectedCompany?._id,
                                  row.agrUID,
                                )}
                              >
                                {row.agreement.agreementTitle}
                              </Link>
                            ),
                          },
                          {
                            name: 'Status',
                            sortable: false,
                            right: true,
                            grow: 0.3,
                            cell: row => (
                              <span className={`tableBadge ${row.userStatus}`}>
                                {row.userStatus}
                              </span>
                            ),
                          },
                        ]}
                        data={filteredAgreements}
                        fixedHeader
                        customStyles={tableStyle}
                      />
                    )}
                    {filteredAgreements.length === 0 && (
                      <div className="rdt_Table">
                        <div className="noDocumentsMessage">
                          <h2>No documents to display</h2>
                          <p>
                            Any document relating to this contact <br /> will be
                            listed here.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            )}
          </div>
        ) : (
          <div className="loaderWrapper">
            <i className="gg-spinner mr-10"></i>
            Fetching contact data...
          </div>
        )}
      </LayoutWrapper>
    </>
  );
}
