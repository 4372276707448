export const urls = {
  login: 'user/login',
  register: 'user/signup',
  logout: 'user/logout',
  requestForgotPassword: 'user/issuepin',
  verifyForgotPasswordPin: 'user/verifypin',
  resetPassword: 'user/password?type=reset',
  changePassword: '/user/changepassword',
  getUser: 'user?id=',
  editUser: 'user/edit',
  verifyToken: 'user/verify?token=',
  resendVerifyLink: 'user/resendVerifyLink',
  updateDefaultcompany: 'user/defaultCompany',
  updateUserDemo: 'user/updateDemo',
  updateUserSign: '/user/uploadSignature',
  removeUserSignature: '/user/removeUserSignature',
  updateUserSignCrop: '/user/updateSignatureCrop',
  createGhostUserAndImportDoc: (id, accessKey) =>
    `/user/ghost/import?id=${id}&accessKey=${accessKey}`,
  userChangeSubscription: 'user/changeSubscription',

  hubspotOauth: '/hubspot/oauth',

  getAccounts: 'companies/all?id=',
  getUserAgreements: 'user/agreements?id=',

  setPassword: 'user/password?type=new',
  acceptCompanyInvitation: 'company/invitation/accept',
  declineCompanyInvitation: 'company/invitation/decline',

  createCompany: 'company/create',
  viewCompany: (cId, uId) => `company?id=${cId}&uid=${uId}`,
  updateCompany: 'company/edit',
  deleteCompany: cId => `company?id=${cId}`,

  companyAgreements: (cId, uId) => `company/allAgreements?id=${cId}&uId=${uId}`,
  inviteMember: 'company/inviteMember',
  inviteMembers: 'company/inviteMembers',
  deleteCompanyMember: (uid, cid, contid) =>
    `company/member?cid=${cid}&id=${uid}&contid=${contid}`,
  companyMember: 'company/member',
  companyMembers: cId => `company/members?cid=${cId}`,
  companyContacts: cId => `company/contacts?cid=${cId}`,
  deleteCompanyContact: (contactType, contactId, companyId) =>
    `company/contact?contactType=${contactType}&contactId=${contactId}&companyId=${companyId}`,
  getContact: (cId, contactId, contactType) =>
    `company/contact?id=${contactId}&cid=${cId}&cType=${contactType}`,
  updateContact: 'company/contact',
  updateGuestContact: (id, accessKey) =>
    `company/guestContact?id=${id}&accessKey=${accessKey}`,
  createContact: 'company/contact',
  createGuestContact: (id, accessKey) =>
    `company/guestContact?id=${id}&accessKey=${accessKey}`,
  acceptMemberInvite: 'company/invitation/accept',
  declineMemberInvite: 'company/invitation/decline',
  checkMemberExists: 'company/membersCheck',
  updateEventListener: 'company/updateEventListener',
  registerWebhook: 'company/registerWebhook',
  updateWebhook: 'company/updateWebhook',
  deleteWebhook: 'company/deleteWebhook',
  getCompanyWebhooks: 'company/getCompanyWebhooks',

  getCompanyTemplates: 'documents/all?id=',
  getDefaultTemplates: 'documents/default/all',
  getDefaultTemplateById: Id => `document/default?id=${Id}`,
  getTemplateDocument: Id => `document?id=${Id}`,
  importDocument: 'document/import',
  getInboundDoc: id => `inboundDoc/get?id=${id}`,
  updateInboundDoc: 'inboundDoc/update',

  uploadTemplate: 'template',
  getTemplates: cId => `templates?cId=${cId}`,
  viewTemplate: templateId => `template?templateId=${templateId}`,
  updateTemplate: 'template',
  deleteTemplate: templateId => `template?templateId=${templateId}`,
  draftFromTemplate: '/template/newDraft',
  generateDocLink: '/template/newGenerateLink',
  generateDraftFromLink: '/template/generateDraftFromLink',
  verifyTemplate: (templateId, accessKey) =>
    `/template/verify?templateId=${templateId}&accessKey=${accessKey}`,
  importGlobalTemplate: '/template/importGlobalTemplate',

  getAgreement: 'agreement?id=',
  getIndividualAgreement: 'individualAgreement?id=',
  getGuestAgreement: (id, accessKey, viewType) =>
    `guestAgreement?id=${id}&accessKey=${accessKey}&viewType=${viewType}`,
  updateAgreement: 'agreement',
  updateAgreementByGuest: (id, accessKey) =>
    `guestUpdateAgreement?id=${id}&accessKey=${accessKey}`,
  approveAgreement: 'approveAgreement',
  declineAgreement: 'declineAgreement',
  signAgreement: 'signAgreement',
  resendAgreement: 'agreement/resend',
  rescindAgreement: 'agreement/rescind',
  guestSignAgreement: (id, accessKey) =>
    `guestSignAgreement?id=${id}&accessKey=${accessKey}`,
  // getContact: (cId, uId) => `company?id=${cId}&uid=${uId}`,
  confirmAgreementContact: 'agreement/contact/confirm',
  guestConfirmAgreementContact: (id, accessKey) =>
    `agreement/contact/guestConfirm?id=${id}&accessKey=${accessKey}`,
  importAgreementToUser: '/agreement/importToUser',
  importAgreementToCompany: '/agreement/importToCompany',
  forwardToCompanySigner: (id, accessKey) =>
    `/agreement/forwardToCompanySigner?id=${id}&accessKey=${accessKey}`,
  resendToCompanySigner: (id, accessKey) =>
    `/agreement/resendToCompanySigner?id=${id}&accessKey=${accessKey}`,
  getImportDocs: '/agreement/getImportDocs',
  importUserDocToCompany: '/agreement/importUserDocToCompany',
  resendNewLink: 'agreement/resendNewLink',
  changeRecipient: 'agreement/changeRecipient',
  generateDigitalCertificate: 'agreement/generateDigitalCertificate',
  guestGenerateDigitalCertificate: (id, accessKey) =>
    `agreement/guestGenerateDigitalCertificate?id=${id}&accessKey=${accessKey}`,
  changeDocumentPdf: 'agreement/changePdf',
  downloadAgreementZip: 'agreements/download',

  checkGuestAgreementUser: (id, accessKey) =>
    `agreement/guest/checkUser?id=${id}&accessKey=${accessKey}`,

  downloadZip: `drafts/download`,
  sendAgreement: 'draft/send',

  getDocAttachments: docId => `docAttachments?docId=${docId}`,
  uploadDocAttachment: `docAttachment`,
  removeDocAttachment: (attId, docId) =>
    `docAttachment?attId=${attId}&docId=${docId}`,

  getHubspotData: `hubspot/oauth`,
  getIntegratedCompanyContacts: cId => `hubspot/contacts?access_token=${cId}`,

  createFolder: 'folder',
};
