import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { layoutSaga } from './saga';
import { LayoutState } from './types';

export const initialState: LayoutState = {
  isGettingUser: false,
  user: null,
  gettingUserError: null,
  isGettingCompanies: false,
  companies: [],
  gettingCompaniesError: null,
  selectedCompany: null,
  selectedCompanyContacts: [],
  isLoggingOut: false,
  isSidebarCollapsed: false,
  isSidebarExtrasVisible: true,
  selectedSidebarItem: 'library',
  isUpdatingSelectedCompany: false,
  isSidebarHidden: false,

  selectedFolder: {
    id: '',
    name: 'My To Do',
    folder: 'myTodo',
  },

  isUploadingDoc: false,
  uploadDocError: null,
  isUploadingTemplate: false,
  uploadTemplateError: null,

  isCreatingFolder: false,
  createFolderError: null,
  isAcceptingCompanyInvite: false,
  acceptCompanyInviteError: null,
  userRoles: [],
  isSubscriptionNoticeVisible: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<any>) {
      state.isGettingUser = true;
      state.gettingUserError = null;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.isGettingUser = false;
      state.user = action.payload;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.isGettingUser = false;
      state.gettingUserError = action.payload.message;
    },

    getCompanies(state, action: PayloadAction<any>) {
      state.isGettingCompanies = true;
      state.gettingCompaniesError = null;
    },
    getCompaniesSuccess(state, action: PayloadAction<any>) {
      state.isGettingCompanies = false;
      state.companies = action.payload.companies;
    },
    getCompaniesError(state, action: PayloadAction<any>) {
      state.isGettingCompanies = false;
      state.gettingCompaniesError = action.payload.message;
    },

    setSelectedCompany(state, action: PayloadAction<any>) {
      state.selectedCompany = action.payload.company;

      if (action.payload.company)
        state.companies.forEach((item, key) => {
          if (item.company._id === action.payload.company._id) {
            state.userRoles = item.user.memberRoles;
          }
        });

      if (action.payload.company && action.payload.company.contacts)
        state.selectedCompanyContacts = [
          ...action.payload.company?.contacts?.companies.map(contact => ({
            ...contact,
            name: contact.companyName,
            email: contact.noticeEmail,
          })),
          ...action.payload.company?.contacts?.individuals,
        ];
    },

    updateCompanyById(state, action: PayloadAction<any>) {
      // const { companyId, contact } = action.payload;
      const companyId = action.payload._id;

      const updatedCompanies = state.companies.map(obj =>
        obj.company?.companyId === companyId
          ? {
              ...obj,
              company: { ...action.payload, companyId },
            }
          : obj,
      );

      state.companies = updatedCompanies;
    },
    updateCompanyVariableById(state, action: PayloadAction<any>) {
      const { companyId, ...variables } = action.payload;
      let updatedCompany: any = null;

      const updatedCompanies = state.companies.map(obj => {
        if (obj.company?.companyId === companyId) {
          updatedCompany = {
            ...obj,
            company: { ...obj.company, ...variables },
          };
          return updatedCompany;
        } else return obj;
      });

      state.companies = updatedCompanies;

      if (state.selectedCompany?._id === companyId && updatedCompany) {
        state.selectedCompany = updatedCompany.company;

        if (variables.contacts)
          state.selectedCompanyContacts = [
            ...variables?.contacts?.companies.map(contact => ({
              ...contact,
              name: contact.companyName,
              email: contact.noticeEmail,
            })),
            ...variables?.contacts?.individuals,
          ];
      }
    },

    updateSelectedCompany(state, action: PayloadAction<any>) {
      state.isUpdatingSelectedCompany = true;
    },
    updateSelectedCompanySuccess(state, action: PayloadAction<any>) {
      state.isUpdatingSelectedCompany = false;
    },
    updateSelectedCompanyError(state, action: PayloadAction<any>) {
      state.isUpdatingSelectedCompany = false;
    },
    logOut(state) {
      state.isLoggingOut = true;
    },
    logOutSuccess(state, action: PayloadAction<any>) {
      state.isLoggingOut = false;
    },
    logOutError(state, action: PayloadAction<any>) {
      state.isLoggingOut = false;
    },
    setIsSidebarCollapsed(state, action: PayloadAction<any>) {
      state.isSidebarCollapsed = action.payload;
    },
    setIsSidebarExtrasVisible(state, action: PayloadAction<any>) {
      state.isSidebarExtrasVisible = action.payload;
    },
    setSelectedSidebarItem(state, action: PayloadAction<any>) {
      state.selectedSidebarItem = action.payload;
    },
    setIsSidebarHidden(state, action: PayloadAction<any>) {
      state.isSidebarHidden = action.payload;
    },

    setSelectedFolder(state, action: PayloadAction<any>) {
      state.selectedFolder = action.payload;
    },
    resetSelectedFolder(state) {
      state.selectedFolder = initialState.selectedFolder;
    },

    uploadDoc(state, action: PayloadAction<any>) {
      state.isUploadingDoc = true;
      state.uploadDocError = null;
    },
    uploadDocSuccess(state, action: PayloadAction<any>) {
      state.isUploadingDoc = false;
    },
    uploadDocError(state, action: PayloadAction<any>) {
      state.isUploadingDoc = false;
      state.uploadDocError = action.payload.message;
    },

    uploadTemplate(state, action: PayloadAction<any>) {
      state.isUploadingTemplate = true;
      state.uploadTemplateError = null;
    },
    uploadTemplateSuccess(state, action: PayloadAction<any>) {
      state.isUploadingTemplate = false;
    },
    uploadTemplateError(state, action: PayloadAction<any>) {
      state.isUploadingTemplate = false;
      state.uploadTemplateError = action.payload.message;
    },

    createFolder(state, action: PayloadAction<any>) {
      state.isCreatingFolder = true;
      state.createFolderError = null;
    },
    createFolderSuccess(state, action: PayloadAction<any>) {
      state.isCreatingFolder = false;
      state.selectedCompany.folders = [
        ...state.selectedCompany.folders,
        action.payload.folder,
      ];
    },
    createFolderError(state, action: PayloadAction<any>) {
      state.isCreatingFolder = false;
      state.createFolderError = action.payload.message;
    },

    acceptCompanyInvite(state, action: PayloadAction<any>) {
      state.isAcceptingCompanyInvite = true;
      state.acceptCompanyInviteError = null;
    },
    acceptCompanyInviteSuccess(state, action: PayloadAction<any>) {
      state.isAcceptingCompanyInvite = false;
      const userTemp = { ...state.user };
      userTemp.tasks = userTemp.tasks.filter(
        task => task._id !== action.payload.taskId,
      );
      state.user = userTemp;
    },
    acceptCompanyInviteError(state, action: PayloadAction<any>) {
      state.isAcceptingCompanyInvite = false;
      state.acceptCompanyInviteError = action.payload.message;
    },

    removeTaskFromUser(state, action: PayloadAction<any>) {
      const updatedTasks = state.user.tasks.filter(
        task => task._id !== action.payload.taskId,
      );
      state.user = {
        ...state.user,
        tasks: updatedTasks,
      };
    },

    updateSignatureDetails(state, action: PayloadAction<any>) {
      state.user = {
        ...state.user,
        signature: action.payload,
      };
    },

    setIsSubscriptionNoticeVisible(state, action: PayloadAction<any>) {
      state.isSubscriptionNoticeVisible = action.payload;
    },
  },
});

export const { actions: layoutActions } = slice;

export const useLayoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: layoutSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLayoutSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
