import { call, put, takeLatest } from 'redux-saga/effects';
import api, { withAuthConfig } from 'utils/api';
import { urls } from 'utils/apiUrls';
import { loginActions as actions } from '.';
import { toast } from 'react-toast';
import { setUser } from 'utils/auth';

function* doLogin(data) {
  try {
    const res = yield call(
      api.post,
      urls.login,
      data.payload.request,
      withAuthConfig,
    );

    setUser(res.user);
    yield put(actions.loginSuccess(res));
    if (data.payload.successCb) {
      data.payload.successCb(res.user);
    }
  } catch (error: any) {
    yield put(actions.loginError(error));
    toast.error(error.message || 'Something went wrong while logging in');
    if (!error.success && data.payload.errorCb) data.payload.errorCb(error);
    if (error.statusCode === 402) data.payload.verifyCb();
    if (error.statusCode === 403) data.payload.setPasswordCb();
  }
}

function* doResendVerifyLink(data) {
  try {
    const res = yield call(
      api.post,
      urls.resendVerifyLink,
      data.payload,
      withAuthConfig,
    );

    toast.success('Verification link sent successfully');
    yield put(actions.doResendVerifyLinkSuccess(res));
  } catch (error: any) {
    toast.error(error.message || 'Something went wrong');
    yield put(actions.doResendVerifyLinkError(error));
  }
}

// request password reset
function* doRequestPasswordReset(data) {
  try {
    const res = yield call(
      api.post,
      urls.requestForgotPassword,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('If your email is associated with an account a Password reset link has been sent');
    yield put(actions.requestPasswordResetSuccess(res));

    if (data.payload.successCb) data.payload.successCb(res);
  } catch (error: any) {
    toast.error(error.message || 'Something went wrong');
    yield put(actions.requestPasswordResetError(error));

    if (data.payload.errorCb) data.payload.errorCb(error);
  }
}

export function* loginSaga() {
  yield takeLatest(actions.login.type, doLogin);
  yield takeLatest(actions.doResendVerifyLink.type, doResendVerifyLink);
  yield takeLatest(actions.requestPasswordReset.type, doRequestPasswordReset);
}
