/**
 *
 * Asynchronously loads the component for NewTemplates
 *
 */

import { lazyLoad } from 'utils/loadable';

export const NewTemplates = lazyLoad(
  () => import('./index'),
  module => module.NewTemplates,
);
