/**
 *
 * Asynchronously loads the component for GuestIndividualAccount
 *
 */

import { lazyLoad } from 'utils/loadable';

export const GuestIndividualAccount = lazyLoad(
  () => import('./index'),
  module => module.GuestIndividualAccount,
);
