import React from 'react';
import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

const RouteChangeTracker = ({ history }) => {
  history.listen((location, action) => {
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);

    ReactGA4.send({ hitType: 'pageview', page: history.location.pathname });
  });

  return <div></div>;
};



export default withRouter(RouteChangeTracker);
