/**
 *
 * Asynchronously loads the component for LoginLinkRedirect
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LoginLinkRedirect = lazyLoad(
  () => import('./index'),
  module => module.LoginLinkRedirect,
);
