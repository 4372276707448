import React from 'react';
import { useLocation } from 'react-router-dom';

export const otherTimeZones = {
  'US/Canada': {
    'America/Los_Angeles': {
      title: 'Pacific Time - US &amp; Canada',
      value: '',
    },
    'America/Denver': {
      title: 'Mountain Time - US &amp; Canada',
      value: '',
    },
    'America/Chicago': {
      title: 'Central Time - US &amp; Canada',
      value: '',
    },
    'America/New_York': {
      title: 'Eastern Time - US &amp; Canada',
      value: '',
    },
    'America/Halifax': {
      title: 'Atlantic Time',
      value: '',
    },
    'America/Anchorage': {
      title: 'Alaska Time',
      value: '',
    },
    'America/Phoenix': {
      title: 'Arizona Time',
      value: '',
    },
    'America/St_Johns': {
      title: 'Newfoundland Time',
      value: '',
    },
    'Pacific/Honolulu': {
      title: 'Hawaii Time',
      value: '',
    },
  },

  America: {
    'America/Adak': {
      title: 'America/Adak',
      value: 'GMT-10:00',
    },
    'America/Argentina/Buenos_Aires': {
      title: 'Buenos Aires Time',
      value: 'GMT-03:00',
    },

    'America/Asuncion': {
      title: 'Asuncion Time',
      value: 'GMT-04:00',
    },
    'America/Bogota': {
      title: 'Bogota, Jamaica, Lima Time',
      value: 'GMT-05:00',
    },

    'America/Campo_Grande': {
      title: 'America/Campo_Grande',
      value: 'GMT-04:00',
    },

    'America/Caracas': {
      title: 'Caracas Time',
      value: 'GMT-04:00',
    },
    'America/Godthab': {
      title: 'America/Godthab',
      value: 'GMT-03:00',
    },
    'America/Goose_Bay': {
      title: 'Atlantic Time',
      value: 'GMT-04:00',
    },
    'America/Guatemala': {
      title: 'Saskatchewan, Guatemala, Costa Rica Time',
      value: 'GMT-06:00',
    },

    'America/Havana': {
      title: 'America/Havana',
      value: 'GMT-05:00',
    },
    'America/Mazatlan': {
      title: 'America/Mazatlan',
      value: 'GMT-07:00',
    },
    'America/Mexico_City': {
      title: 'Mexico City Time',
      value: 'GMT-06:00',
    },

    'America/Montevideo': {
      title: 'Montevideo Time',
      value: 'GMT-03:00',
    },

    'America/Miquelon': {
      title: 'America/Miquelon',
      value: 'GMT-03:00',
    },
    'America/Noronha': {
      title: 'America/Noronha',
      value: 'GMT-02:00',
    },
    'America/Santiago': {
      title: 'Santiago Time',
      value: 'GMT-04:00',
    },
    'America/Santa_Isabel': {
      title: 'America/Santa_Isabel',
      value: 'GMT-08:00',
    },

    'America/Santo_Domingo': {
      title: 'Atlantic Standard Time',
      value: 'GMT-04:00',
    },

    'America/Sao_Paulo': {
      title: 'Brasilia Time',
      value: 'GMT-03:00',
    },
  },
  Africa: {
    'Africa/Cairo': { value: 'GMT+02:00', title: 'Africa/Cairo' },
    'Africa/Johannesburg': { value: 'GMT+02:00', title: 'Central Africa Time' },
    'Africa/Lagos': { value: 'GMT+01:00', title: 'West Africa Time' },
    'Africa/Windhoek': { value: 'GMT+01:00', title: 'Africa/Windhoek' },
  },
  Asia: {
    'Asia/Amman': {
      value: 'GMT+02:00',
      title: 'Syria, Jordan Time',
    },
    'Asia/Baghdad': {
      value: 'GMT+03:00',
      title: 'Baghdad, East Africa Time',
    },
    'Asia/Baku': {
      value: 'GMT+04:00',
      title: 'Asia/Baku',
    },
    'Asia/Beirut': {
      value: 'GMT+02:00',
      title: 'Jordan, Lebanon Time',
    },
    'Asia/Damascus': {
      value: 'GMT+02:00',
      title: 'Asia/Damascus',
    },
    'Asia/Dhaka': {
      value: 'GMT+06:00',
      title: 'Asia/Dhaka',
    },
    'Asia/Dubai': {
      value: 'GMT+04:00',
      title: 'Dubai Time',
    },
    'Asia/Gaza': {
      value: 'GMT+02:00',
      title: 'Asia/Gaza',
    },
    'Asia/Irkutsk': {
      value: 'GMT+08:00',
      title: 'Asia/Irkutsk',
    },
    'Asia/Jakarta': {
      value: 'GMT+07:00',
      title: 'Indochina Time',
    },
    'Asia/Jerusalem': {
      value: 'GMT+02:00',
      title: 'Israel Time',
    },
    'Asia/Kabul': {
      value: 'GMT+04:30',
      title: 'Kabul Time',
    },
    'Asia/Kamchatka': {
      value: 'GMT+12:00',
      title: 'Pacific/Majuro',
    },
    'Asia/Karachi': {
      value: 'GMT+05:00',
      title: 'Pakistan, Maldives Time',
    },
    'Asia/Kathmandu': {
      value: 'GMT+05:45',
      title: 'Kathmandu Time',
    },
    'Asia/Kolkata': {
      value: 'GMT+05:30',
      title: 'India, Sri Lanka Time',
    },
    'Asia/Krasnoyarsk': {
      value: 'GMT+07:00',
      title: 'China, Singapore Perth Time',
    },
    'Asia/Omsk': {
      value: 'GMT+06:00',
      title: 'Asia/Omsk',
    },
    'Asia/Rangoon': {
      value: 'GMT+06:30',
      title: 'Asia/Rangoon',
    },
    'Asia/Shanghai': {
      value: 'GMT+08:00',
      title: 'China, Singapore Perth',
    },
    'Asia/Tehran': {
      value: 'GMT+03:30',
      title: 'Tehran Time',
    },
    'Asia/Tokyo': {
      value: 'GMT+09:00',
      title: 'Japan, Korea Time',
    },
    'Asia/Vladivostok': {
      value: 'GMT+10:00',
      title: 'Asia/Vladivostok',
    },
    'Asia/Yakutsk': {
      value: 'GMT+09:00',
      title: 'Asia/Yakutsk',
    },
    'Asia/Yekaterinburg': {
      value: 'GMT+05:00',
      title: 'Asia/Dhaka',
    },
    'Asia/Yerevan': {
      value: 'GMT+04:00',
      title: 'Asia/Yerevan',
    },
  },
  Atlantic: {
    'Atlantic/Azores': { value: 'GMT-01:00', title: 'Azores Time' },
    'Atlantic/Cape_Verde': { value: 'GMT-01:00', title: 'Cape Verde Time' },
  },

  Australia: {
    'Australia/Adelaide': { value: 'GMT+09:30', title: 'Adelaide Time' },
    'Australia/Brisbane': { value: 'GMT+10:00', title: 'Brisbane Time' },
    'Australia/Darwin': { value: 'GMT+09:30', title: 'Australia/Darwin' },
    'Australia/Eucla': { value: 'GMT+08:45', title: 'Australia/Eucla' },
    'Australia/Lord_Howe': { value: 'GMT+10:30', title: 'Australia/Lord_Howe' },
    'Australia/Perth': { value: 'GMT+08:00', title: 'Australia/Perth' },
    'Australia/Sydney': { value: 'GMT+10:00', title: 'Sydney, Melbourne Time' },
  },

  UTC: {
    UTC: { value: 'GMT+00:00', title: 'UTC Time' },
  },

  Europe: {
    'Europe/Berlin': { value: 'GMT+01:00', title: 'Central European Time' },
    'Europe/Helsinki': { value: 'GMT+02:00', title: 'Eastern European Time' },
    'Europe/London': { value: 'GMT+00:00', title: 'UK, Ireland, Lisbon Time' },
    'Europe/Minsk': { value: 'GMT+03:00', title: 'Moscow, Minsk Time' },
    'Europe/Moscow': { value: 'GMT+03:00', title: 'Yerevan Time' },
  },

  Pacific: {
    'Pacific/Apia': { value: 'GMT+13:00', title: 'Pacific/Apia' },
    'Pacific/Auckland': { value: 'GMT+12:00', title: 'Auckland Time' },
    'Pacific/Chatham': { value: 'GMT+12:45', title: 'Pacific/Chatham' },
    'Pacific/Easter': { value: 'GMT-06:00', title: 'Pacific/Easter' },
    'Pacific/Fiji': { value: 'GMT+12:00', title: 'Pacific/Fiji' },
    'Pacific/Gambier': { value: 'GMT-09:00', title: 'Pacific/Gambier' },
    'Pacific/Kiritimati': { value: 'GMT+14:00', title: 'Pacific/Kiritimati' },
    'Pacific/Majuro': { value: 'GMT+12:00', title: 'Pacific/Majuro' },
    'Pacific/Marquesas': { value: 'GMT-10:30', title: 'Pacific/Marquesas' },
    'Pacific/Norfolk': { value: 'GMT+11:00', title: 'Pacific/Norfolk' },
    'Pacific/Noumea': { value: 'GMT+11:00', title: 'Pacific/Noumea' },
    'Pacific/Pago_Pago': { value: 'GMT-11:00', title: 'Pacific/Pago_Pago' },
    'Pacific/Pitcairn': { value: 'GMT-08:00', title: 'Pacific/Pitcairn' },
    'Pacific/Tarawa': { value: 'GMT+12:00', title: 'Pacific/Tarawa' },
    'Pacific/Tongatapu': { value: 'GMT+13:00', title: 'Pacific/Tongatapu' },
  },
};

export const isGhostContact = contact => {
  return contact.contactType === 'ghost';
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function isValidDocName(text: string): boolean {
  //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
  var regex = /^[A-Za-z0-9 -_.:]+$/;

  //Validate TextBox value against the Regex.
  var isValid = regex.test(text);

  return isValid;
}

export function sanitizeDocName(name: string): string {
  return name.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\]/g, '');
}


export const validateEmail = email => {
  // check email
  let regCheck = email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return regCheck;
};

export const validatePhoneFormat = '\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$'
