/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toast';

import 'styles/colors.css';
import 'styles/icons.css';
import { GlobalStyle } from 'styles/global-styles';
import 'styles/commonStyles.scss';
import 'styles/css_gg.css';

import { useTranslation } from 'react-i18next';

import { CompanyDashboard } from './pages/CompanyDashboard/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { NewAccount } from './pages/NewAccount';
import { CompleteAccount } from './pages/CompleteAccount/Loadable';
import { NewCompany } from './pages/NewCompany/Loadable';
import { VerifyAccount } from './pages/VerifyAccount/Loadable';
import { Login } from './pages/Login';
import { ContactsPage } from './pages/ContactsPage/Loadable';
import { CreateContact } from './pages/CreateContact/Loadable';
import { routes } from 'utils/routes';
import { CompanySettings } from './pages/CompanySettings/Loadable';
import { SingleContactPage } from './pages/SingleContactPage';
import { IndividualDashboard } from './pages/IndividualDashboard';
import { ViewDocument } from './pages/ViewDocument/Loadable';
import { MyAccount } from './pages/MyAccount/Loadable';
import { ResetPassword } from './pages/ResetPassword/Loadable';
import { SetGuestPassword } from './pages/SetGuestPassword/Loadable';
import { Templates } from './pages/Templates/Loadable';
import { NewTemplates } from './pages/NewTemplates/Loadable';
import { ViewTemplate } from './pages/ViewTemplate/Loadable';
import { GuestWelcome } from './pages/GuestWelcome/Loadable';
import { GuestIndividualAccount } from './pages/GuestWelcome/GuestIndividualAccount/Loadable';
import { GuestChoseCompany } from './pages/GuestWelcome/GuestCompanyAccount/Loadable';
import { Integrated } from './pages/Integrated/Loadable';
import RouteChangeTracker from './components/RouteChangeTracker';
import { IndividualDocViewer } from './pages/IndividualDocViewer/Loadable';
import { GuestLinkErrorPage } from './pages/GuestLinkErrorPage/Loadable';
import { LoginLinkRedirect } from './pages/LoginLinkRedirect/Loadable';
import { GenerateDocFromLink } from './pages/GenerateDocFromLink/Loadable';

declare global {
  interface Window {
    USETIFUL?: any;
    Headway?: any;
  }
}

export function App() {
  const { i18n } = useTranslation();

  const maintenance = false;

  const maintenanceComingSoon = false;
  return (
    <>
          {maintenanceComingSoon && (
        <div className='alertBanner'>
          We are currently experiencing intermittent issues with the new patch and our team is actively working to resolve them.
        </div>
      )}
      {maintenance ? (
        <div className="maintenance login-wrapper bg-primary">
          <div className="formHolder">
            <div className="loginBox pl-20 pr-20">
              <div className="logoIMG">
                <img
                  width="90"
                  src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/animated-logo-huge.gif"
                  alt=""
                ></img>
              </div>
              <div className="title">🚧 Down for maintenance</div>
              <div className="w-100 pb-20" style={{ maxWidth: '462px' }}>
                We apologize for the inconvenience. Exciting changes are coming.
                We'll be back soon.
              </div>
            </div>
          </div>
        </div>
      )  : (
        <BrowserRouter>
          <Helmet
            titleTemplate="%s - ContractHub"
            defaultTitle="ContractHub"
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content="ContractHub" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;"  />
          </Helmet>

          <Switch>
            <Route exact path={routes.newAccount} component={NewAccount} />
            <Route exact path={routes.createCompany} component={NewCompany} />
            <Route exact path={routes.login} component={Login} />
            <Route
              exact
              path={routes.guestWelcome()}
              component={GuestWelcome}
            />
            <Route
              exact
              path={routes.directLoginLink()}
              component={LoginLinkRedirect}
            />
            <Route
              exact
              path={routes.guestIndividualAccount()}
              component={GuestIndividualAccount}
            />
            <Route
              exact
              path={routes.guestChoseCompany()}
              component={GuestChoseCompany}
            />
            <Route
              exact
              path={routes.verifyAccount()}
              component={VerifyAccount}
            />
            <Route exact path={routes.myAccount} component={MyAccount} />

            <Route
              exact
              path={routes.forgotPassword}
              component={ResetPassword}
            />
            <Route
              exact
              path={routes.setPassword()}
              component={ResetPassword}
            />

            <Route
              exact
              path={routes.dashboard}
              component={IndividualDashboard}
            />
            <Route
              exact
              path={routes.companyDashboard()}
              component={CompanyDashboard}
            />
            <Route exact path={routes.contacts()} component={ContactsPage} />
            <Route
              exact
              path={routes.contact()}
              component={SingleContactPage}
            />
            <Route exact path={routes.newContact()} component={CreateContact} />
            <Route
              exact
              path={routes.companySettings()}
              component={CompanySettings}
            />
            <Route
              exact
              path={routes.viewCompanyDocument()}
              component={ViewDocument}
            />
            <Route
              exact
              path={routes.guestViewDocument()}
              component={ViewDocument}
            />
            <Route
              exact
              path={routes.viewSelfDocument()}
              component={IndividualDocViewer}
            />
            <Route
              exact
              path={routes.completeAccount()}
              component={CompleteAccount}
            />
            <Route
              exact
              path={routes.setGuestPassword()}
              component={SetGuestPassword}
            />
            {/* route for guestLinkExpired */}
            <Route
              exact
              path={routes.guestLinkExpired()}
              component={GuestLinkErrorPage}
            />

            <Route exact path={routes.viewTemplates()} component={Templates} />
            <Route
              exact
              path={routes.viewTemplate()}
              component={ViewTemplate}
            />
            <Route exact path={routes.newTemplate()} component={NewTemplates} />
            <Route
              exact
              path={routes.generateDocFromLink}
              component={GenerateDocFromLink}
            />

            <Route exact path={routes.integrated()} component={Integrated} />

            <Redirect from="/" to={routes.dashboard} />
            <Route component={NotFoundPage} />
          </Switch>
          <GlobalStyle />

          <ToastContainer
            delay={5000}
            css={{
              zIndex: 9999,
            }}
            position="top-right"
          />
          <RouteChangeTracker />
        </BrowserRouter>
      )}

    </>
  );
}
