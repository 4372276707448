/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import ReactGA from 'react-ga4';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// api layer init
import { setResponseInterceptor } from './utils/api';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';



const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
var route = window.location.pathname + window.location.search;


Sentry.init({
  dsn:
    'https://d81cf0289b854dfa8499c7b1629e95ba@o317014.ingest.sentry.io/1801357',
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({ useCompression: false }),
  ],
  tracesSampleRate: 1.0,
  environment:
    'ch-webClient-main@' + process.env.REACT_APP_NODE_ENV || 'development',
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // release: "ch-webClient-main@" + process.env.REACT_APP_NODE_ENV || "development",
});

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

const { dispatch } = store; // direct access to redux store.
setResponseInterceptor(dispatch);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
