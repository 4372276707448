import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loginSaga } from './saga';
import { LoginState } from './types';

export const initialState: LoginState = {
  isLoggingIn: false,
  loginError: null,
  user: null,
  isLoggedIn: false,
  isResendingVerifyLink: false,
  resendVerifyLinkError: null,
  resendVerifyLinkSuccess: null,
  needsVerification: false,
  isRequestingPasswordReset: false,
  requestPasswordResetError: null,
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      state.isLoggedIn = false;
      state.isLoggingIn = true;
      state.loginError = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.isLoggingIn = false;
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    loginError(state, action: PayloadAction<any>) {
      state.isLoggingIn = false;
      state.loginError = action.payload.message;
    },
    doResendVerifyLink(state, action: PayloadAction<any>) {
      state.isResendingVerifyLink = true;
      state.resendVerifyLinkError = null;
      state.needsVerification = true;
    },
    doResendVerifyLinkSuccess(state, action: PayloadAction<any>) {
      state.isResendingVerifyLink = false;
      state.loginError = null;
      state.resendVerifyLinkSuccess = true;
      state.needsVerification = false;
    },
    doResendVerifyLinkError(state, action: PayloadAction<any>) {
      state.isResendingVerifyLink = false;
      state.resendVerifyLinkError = action.payload.message;
    },
    resetLoginError(state) {
      state.loginError = null;
    },
    requestPasswordReset(state, action: PayloadAction<any>) {
      state.isRequestingPasswordReset = true;
      state.requestPasswordResetError = null;
    },
    requestPasswordResetSuccess(state, action: PayloadAction<any>) {
      state.isRequestingPasswordReset = false;
    },
    requestPasswordResetError(state, action: PayloadAction<any>) {
      state.isRequestingPasswordReset = false;
      state.requestPasswordResetError = action.payload.message;
    }
  },
});

export const { actions: loginActions } = slice;

export const useLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLoginSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
