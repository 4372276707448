import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { newAccountSaga } from './saga';
import { NewAccountState } from './types';

export const initialState: NewAccountState = {
  registering: false,
  error: null,
  registerResponse: {},
  stepNo: 0,
  emailAlreadyRegistered: false,
};

const slice = createSlice({
  name: 'newAccount',
  initialState,
  reducers: {
    onRegister(state, action: PayloadAction<any>) {
      state.registering = true;
      state.error = null;
    },
    onRegisterSuccess(state, action: PayloadAction<any>) {
      state.registering = false;
      state.stepNo = 1;
    },
    onClearEmailAlreadyExists(state) {
      state.emailAlreadyRegistered = false;
    },
    onRegisterFail(state, action: PayloadAction<any>) {
      state.registering = false;
      state.error = action.payload.message;
      if (action.payload.message.includes('exists')){
        state.emailAlreadyRegistered = true;
      }
    },
  },
});

export const { actions: newAccountActions } = slice;

export const useNewAccountSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: newAccountSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useNewAccountSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
