import { call, put, takeLatest } from 'redux-saga/effects';
import api, { withAuthConfig } from 'utils/api';
import { urls } from 'utils/apiUrls';
import { individualDashboardActions as actions } from '.';
import { toast } from 'react-toast';

function* doGetDocuments(data) {
  try {
    const res = yield call(
      api.get,
      urls.getUserAgreements + data.payload.userId,
      withAuthConfig,
    );

    yield put(
      actions.getDocumentsSuccess({
        allAgreements: res.folders,
        folderAgreements: res.folders[data.payload.selectedFolder],
      }),
    );
  } catch (error) {
    toast.error('Something went wrong while fetching documents');
    yield put(actions.getDocumentsError(error));
  }
}

export function* individualDashboardSaga() {
  yield takeLatest(actions.getDocuments.type, doGetDocuments);
}
