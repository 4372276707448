/**
 *
 * Asynchronously loads the component for ViewDocument
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ViewDocument = lazyLoad(
  () => import('./index'),
  module => module.ViewDocument,
);
