import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  *, body, html {
        font-family: 'Archivo', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        font-size: var(--fontSize-normal);
        text-rendering: optimizeLegibility;
  }
  small {
    font-size: 10pt;

}
  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Archivo, Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
    font-size: var(--fontSize-normal);
  }

  input, select {
    font-family: inherit;
    font-size: var(--fontSize-normal);
  }

  scrollbar-color: rgba(109, 40, 217, 0.5) transparent; /* track thumb */
  scrollbar-width: thin; /* none, thin, or auto */
  &::-webkit-scrollbar {
    width: 4px;
    opacity: 0.5;
  }
  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--font);
    border-radius: 10px;
    border: 1px solid var(--sidebar-border);
    background-clip: content-box;
    width: 10px;
    &:hover {
      background-color: var(--highLight);
    }
  }


`;
