/**
 *
 * Asynchronously loads the component for ViewTemplate
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ViewTemplate = lazyLoad(
  () => import('./index'),
  module => module.ViewTemplate,
);
