import { call, put, takeLatest } from 'redux-saga/effects';
import api, { withAuthConfig } from 'utils/api';
import { urls } from 'utils/apiUrls';
import { viewDocumentActions as actions } from '.';
import { toast } from 'react-toast';
import { routes } from 'utils/routes';
import { layoutActions } from 'app/components/LayoutWrapper/slice';

function* doGetAgreement(data) {
  /**
   * Fetches an agreement from the API based on the provided payload.
   *
   * @param {Object} data - The payload containing the necessary information to fetch the agreement.
   * @param {string} data.payload.accessKey - (Optional) The access key to fetch a guest agreement.
   * @param {string} data.payload.docId - The document ID.
   * @param {string} data.payload.viewType - (Optional) The type of view for the agreement.
   * @param {string} data.payload.companyId - (Optional) The ID of the company.
   * @returns {void}
   */
  try {
    // Construct the API URL based on the provided payload.
    const apiUrl = data.payload.accessKey
      ? urls.getGuestAgreement(
          data.payload.docId,
          data.payload.accessKey,
          data.payload.viewType || 'individual',
        )
      : `${urls.getAgreement}${data.payload.docId}&viewType=${data.payload.viewType}${
          data.payload.companyId ? `&cId=${data.payload.companyId}` : ''
        }`;

    // Make the API call to fetch the agreement.
    const res = yield call(
      api.get,
      apiUrl,
      withAuthConfig,
    );

    // Dispatch the success action with the response data.
    yield put(actions.getAgreementSuccess(res));
  } catch (error: any) {
    // Display an error toast with the error message.
    toast.error(
      error?.message ||
        error?.error?.message ||
        'Something went wrong while fetching agreement',
    );

    // Dispatch the error action with the error message.
    yield put(actions.getAgreementError(error.message));
  }
}

function* doGetDocument(data) {
  try {
    const res = yield call(
      api.get,
      urls.getInboundDoc(data.payload),
      withAuthConfig,
    );

    yield put(actions.getDocumentSuccess(res));
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while fetching document',
    );
    yield put(actions.getDocumentError(error));
  }
}

function* doUpdateDocument(data) {
  try {
    const res = yield call(
      api.post,
      data.payload.accessKey
        ? urls.updateAgreementByGuest(
            data.payload.docId,
            data.payload.accessKey,
          )
        : urls.updateAgreement,
      data.payload.request,
      withAuthConfig,
    );

    yield put(actions.updateDocumentSuccess(res));

    if (data.payload.type === 'delete') {
      toast.success('Document deleted successfully');
    }

    if (data.payload.successCb) data.payload.successCb(res.data);
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while updating document',
    );
    yield put(actions.updateDocumentError(error.message));
  }
}

function* doApproveAgreement(data) {
  try {
    const res = yield call(
      api.post,
      urls.approveAgreement,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Agreement approved');
    if (data.payload?.agrUID)
      yield put(actions.getAgreement({ docId: data.payload.agrUID }));
    yield put(actions.approveDocumentSuccess(res.data));

    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while approving document',
    );
    yield put(actions.approveDocumentError(error.message));
  }
}

function* doDeclineAgreement(data) {
  try {
    const res = yield call(
      api.post,
      urls.declineAgreement,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Agreement declined');
    yield put(actions.getAgreement({ docId: data.payload.agrUID }));
    yield put(actions.declineDocumentSuccess(res.data));
    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while declining document',
    );
    yield put(actions.declineDocumentError(error.message));
  }
}

function* doSignAgreement(data) {
  try {
    const res = yield call(
      api.post,
      data.payload.accessKey
        ? urls.guestSignAgreement(data.payload.id, data.payload.accessKey)
        : urls.signAgreement,
      data.payload.request,
      {
        ...withAuthConfig,
        headers: {
          ...withAuthConfig.headers,
          contentType: 'multipart/form-data',
        },
      },
    );

    //     toast.success('Agreement sign successfull');

    if (data.payload.successCb) data.payload.successCb(res);

    yield put(actions.signAgreementSuccess(res.updatedAgreement));
  } catch (error: any) {
    toast.error(
      error?.message ||
        error?.error?.message ||
        'Something went wrong while signing agreement',
    );
    yield put(actions.signAgreementError(error?.message));
  }
}


/**
 * This function is used to update a contact.
 *
 * @param {Object} data - The data that contains the payload.
 * @param {string} data.payload.accessKey - The access key.
 * @param {string} data.payload.docId - The document id.
 * @param {Object} data.payload.request - The request object.
 * @param {string} data.payload.request.companyId - The company id.
 * @param {string} data.payload.request.contactType - The contact type.
 * @param {string} data.payload.agreementContactRequest - The agreement contact request.
 * @param {string} data.payload.agreementContactRequest.agrUID - The agreement UID.
 * @param {string} data.payload.agreementContactRequest.contactId - The contact id.
 * @param {boolean} data.payload.agreementContactRequest.isSigner - The isSigner flag.
 * @param {Array} data.payload.agreementContactRequest.updatedBoxes - The updated boxes.
 * @param {string} data.payload.agreementContactRequest.name - The name.
 * @param {Function} data.payload.successCb - The success callback.
 *
 * @return {Generator} A promise that resolves with the updated contact.
 */
function* doUpdateContact(data) {

  try {

    // Try to update the contact.
    const res = yield call(
      api.put,

      // The API call depends on the access key.
      data.payload.accessKey
        ? urls.updateGuestContact(data.payload.docId, data.payload.accessKey)
        : urls.updateContact,

      // The request object.
      data.payload.request,

      // The auth config.
      withAuthConfig,
    );

    // Destructure the contact and the contact type.
    const { companyId, ...contact } = data.payload.request;
    const contactType =
      contact.contactType === 'individual' ? 'individuals' : 'companies';

    // If the company exists and the current path does not contain 'individual',
    // then update the company.
    if (
      res.company &&
      res.company[0] &&
      !window.location.pathname.includes('individual')
    ) {
      const company = res.company[0];

      // Update the contacts.
      company.contacts[contactType] = company.contacts[contactType].map(obj =>
        obj._id === contact._id ? contact : obj,
      );

      // Update the layout.
      // yield put(layoutActions.updateCompanyContactsById({ contact, companyId }));

      // Set the selected company.
      yield put(layoutActions.setSelectedCompany(company));

      // Update the company.
      yield put(layoutActions.updateCompanyById(company));
    }

    // Confirm the agreement contact.
    yield call(
      api.post,

      // The API call depends on the access key.
      data.payload.accessKey
        ? urls.guestConfirmAgreementContact(
            data.payload.docId,
            data.payload.accessKey,
          )
        : urls.confirmAgreementContact,

      // The agreement contact request.
      data.payload.agreementContactRequest,

      // The auth config.
      withAuthConfig,
    );

    // Dispatch the update contact info success.
    yield put(
      actions.updateContactInfoSuccess({
        agreementId: data.payload.agreementContactRequest.agrUID,
        contactId: data.payload.agreementContactRequest.contactId,
        partyType: data.payload.agreementContactRequest.isSigner
          ? 'signers'
          : 'recipients',
        updatedBoxes: data.payload.agreementContactRequest.updatedBoxes,
        name: data.payload.agreementContactRequest.name,
        updatedContactInfo: res.contact,
      }),
    );

    // Execute the success callback.
    if (data.payload.successCb) data.payload.successCb();

  } catch (error: any) {
    // Dispatch the update contact info error.
    yield put(actions.updateContactInfoError(error?.message));
  }
}

function* doConfirmAgreementContact(data) {
  try {
    yield call(
      api.post,
      data.payload.accessKey
        ? urls.guestConfirmAgreementContact(
            data.payload.docId,
            data.payload.accessKey,
          )
        : urls.confirmAgreementContact,
      data.payload.agreementContactRequest,
      withAuthConfig,
    );

    yield put(actions.confirmAgreementContactSuccess({}));

    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error?.message || 'Something went wrong while updating contact',
    );
    yield put(actions.confirmAgreementContactError(error?.message));
  }
}

function* doCheckGuestAgreementUser(data) {
  try {
    const res = yield call(
      api.post,
      urls.checkGuestAgreementUser(data.payload.id, data.payload.accessKey),
      data.payload,
      withAuthConfig,
    );

    yield put(actions.checkGuestAgreementUserSuccess(res.data));
  } catch (error: any) {
    yield put(actions.checkGuestAgreementUserError(error.message));
  }
}

function* doResendDocument(data) {
  try {
    const res = yield call(
      api.post,
      urls.resendAgreement,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Document resend success');
    yield put(actions.resendDocumentSuccess(res.data));
    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while resending document',
    );
    yield put(actions.resendDocumentError(error.message));
    if (data.payload.errorCb) data.payload.errorCb();
  }
}

function* doRescindDocument(data) {
  try {
    const res = yield call(
      api.post,
      urls.rescindAgreement,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Document rescind success');
    yield put(actions.rescindDocumentSuccess(res.data));
    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while rescinding document',
    );
    yield put(actions.rescindDocumentError(error.message));
  }
}

function* doImportDocToCompany(data) {
  try {
    const res = yield call(
      api.post,
      urls.importAgreementToCompany,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Document imported successfully');
    yield put(actions.importToCompany(res.data));
    if (data.payload.successCb) data.payload.successCb();
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while importing document',
    );
    yield put(actions.importToCompanyError(error.message));
  }
}

function* doChangeRecipient(data) {
  try {
    const res = yield call(
      api.post,
      urls.changeRecipient,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Recipient changed successfully');
    yield put(actions.changeRecipientSuccess(res.data));
    if (data.payload.successCb) data.payload.successCb(res.data);
  } catch (error: any) {
    toast.error(
      error.message || 'Something went wrong while changing recipient',
    );
    yield put(actions.changeRecipientError(error.message));
  }
}

function* doGenerateDigitalSignatureCertificate(data) {
  try {
    const res = yield call(
      api.post,
      data.payload.accessKey
        ? urls.guestGenerateDigitalCertificate(
            data.payload.docId,
            data.payload.accessKey,
          )
        : urls.generateDigitalCertificate,
      data.payload.request,
      withAuthConfig,
    );

    toast.success('Digital signature certificate generated successfully');
    yield put(actions.generateDigitalSignatureCertificateSuccess(res.data));
    if (data.payload.successCb) data.payload.successCb(res.data);
  } catch (error: any) {
    toast.error(
      error.message ||
        'Something went wrong while generating digital signature certificate',
    );
    yield put(actions.generateDigitalSignatureCertificateError(error.message));
  }
}

function* doUpdateDocPDF(data) {
  try {
    const res = yield call(
      api.post,
      urls.changeDocumentPdf,
      data.payload.form,
      {
        ...withAuthConfig,
        headers: {
          ...withAuthConfig.headers,
          contentType: 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          console.log(progressEvent.loaded);

          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          data.payload.setProgress(percent);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        },
      },
    );

    yield put(actions.updateDocumentPdfSuccess(res));
    data.payload.successCb(res.document.agrUID);
    // toast.success('Document uploaded successfully');
  } catch (error) {
    yield put(actions.updateDocumentPdfError(error));
  }
}

export function* viewDocumentSaga() {
  yield takeLatest(actions.getAgreement.type, doGetAgreement);
  yield takeLatest(actions.getDocument.type, doGetDocument);
  yield takeLatest(actions.updateDocument.type, doUpdateDocument);
  yield takeLatest(actions.approveDocument.type, doApproveAgreement);
  yield takeLatest(actions.declineDocument.type, doDeclineAgreement);
  yield takeLatest(actions.signAgreement.type, doSignAgreement);
  yield takeLatest(actions.updateContactInfo.type, doUpdateContact);
  yield takeLatest(
    actions.checkGuestAgreementUser.type,
    doCheckGuestAgreementUser,
  );
  yield takeLatest(
    actions.confirmAgreementContact.type,
    doConfirmAgreementContact,
  );
  yield takeLatest(actions.resendDocument.type, doResendDocument);
  yield takeLatest(actions.rescindDocument.type, doRescindDocument);
  yield takeLatest(actions.importToCompany.type, doImportDocToCompany);
  yield takeLatest(actions.changeRecipient.type, doChangeRecipient);
  yield takeLatest(actions.updateDocumentPdf.type, doUpdateDocPDF);
  yield takeLatest(
    actions.generateDigitalSignatureCertificate.type,
    doGenerateDigitalSignatureCertificate,
  );
}
