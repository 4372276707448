/**
 * Asynchronously loads the component for CompanyDashboard
 */

import { lazyLoad } from 'utils/loadable';

export const CompanyDashboard = lazyLoad(
  () => import('./index'),
  module => module.CompanyDashboard,
);
