const USER_KEY = 'ch_u';

export const setStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getStorage = key => {
  let item = sessionStorage.getItem(key);

  if (item) {
    try {
      return JSON.parse(atob(item));
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const getUser = () => {
  return getStorage(USER_KEY);
};

export const setUser = user => {
  sessionStorage.setItem(
    USER_KEY,
    btoa(unescape(encodeURIComponent(JSON.stringify(user)))),
  );

  return true;
};

export const clearSession = () => {
  sessionStorage.clear();
};
