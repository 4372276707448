/**
 *
 * DeleteAgreementConfirmModal
 *
 */
import React from 'react';
import Modal from 'react-modal';

import './styles.scss';

interface Props {
  isOpen: boolean;
  closeModal: Function;
  customStyles?: any;
  onDeleteAgreementConfirm: Function;
  isDeletingDoc: boolean;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: '1px solid #707070',
  },
};

export function DeleteAgreementConfirmModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={props.closeModal}
      style={props.customStyles || customStyles}
      contentLabel="Delete agreement confirmation"
    >
      <div className="modal-title">This is not reversible</div>

      <div className="delete-agreement-modal-body">
        <div className="modal-body-content">
          Please confirm you wish to delete this agreement.
        </div>
      </div>

      <div className="footer-btns d-flex">
        <button
          className="greyButton"
          disabled={props.isDeletingDoc}
          type="button"
          onClick={() => props.closeModal()}
        >
          Cancel
        </button>

        <button
          className="blueButton"
          disabled={props.isDeletingDoc}
          onClick={() => {props.onDeleteAgreementConfirm()}}
        >
          {props.isDeletingDoc ? 'Deleting...' : 'Confirm'}
        </button>
      </div>
    </Modal>
  );
}
