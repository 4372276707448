import axios from 'axios';
// import { configureAppStore } from '../store/configureStore';
// import { actions } from '../app/containers/UserHeader/slice';
import { layoutActions } from '../app/components/LayoutWrapper/slice';
import { routes } from './routes';
import { toast } from 'react-toast';
import * as Sentry from '@sentry/react';

// const store = configureAppStore();

let url = window.location.href;
let baseApiUrl;

// staging API server
if (url.includes('app-staging.contracthub.com/')) {
  baseApiUrl = 'https://api.contracthub.com/';
}
// production API server
if (url.indexOf('https://app.contracthub.com/') > -1) {
  baseApiUrl = 'https://api.contracthub.com/';
}

// beta API server
if (url.includes('https://app-beta.contracthub.com/')|| 
url.includes('https://app-dev.contracthub.com/')) {
  baseApiUrl = 'https://api.contracthub.com/';
}
// LOCALHOST
if (url.indexOf('http://localhost:3000/') > -1) {
  baseApiUrl = 'http://localhost:8000/';
  // baseApiUrl = 'http://52.87.250.199:8000/';
}

console.log(process.env.REACT_APP_NODE_ENV);

// dev server
if (url.indexOf('https://gwei22gjxe.us-east-1.awsapprunner.com/') > -1) {
  baseApiUrl = 'https://qf2pm5kpng.us-east-1.awsapprunner.com/';
}

const api = axios.create({
  baseURL: baseApiUrl,
});

const isHandlerEnabled = (config: any = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = request => {
  if (isHandlerEnabled(request)) {
  }
  return request;
};

api.interceptors.request.use(request => requestHandler(request));

const UNAUTHORIZED = 401;
const UNAUTHORIZEDDOCACCESS = 403;
const NODOCFOUND = 414;
const SUBSCRIPTION_LIMIT = 426;

const errorHandler = (error, dispatch) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
    console.log(error);
    Sentry.captureException(error);
  }

  const status = error?.response?.status || 500;
  if (status === UNAUTHORIZED) {
    // dispatch(actions.openLoginModal());
    // toast.info(
    //   '4 You are not authorized to view this document.',
    // );
    window.location.href = `${routes.login}?return_to=${window.location.pathname}&errorText=Hi Please login to your account to continue.`;
  } else if (status === UNAUTHORIZEDDOCACCESS) {
    // toast.error(
    //   'Sorry you do not have access to this document.',
    // );

    // window.location.href = `${routes.login}?return_to=${window.location.pathname}&errorRedirectUrl=${routes.dashboard}&errorText= Sorry you do not have access to this document.`;
  } else if (status === NODOCFOUND) {
    window.location.href = `${routes.login}?errorText= No document found.`;
  } else if (status === SUBSCRIPTION_LIMIT) {
    dispatch(layoutActions.setIsSubscriptionNoticeVisible(true));
  }
  return Promise.reject({ ...error.response?.data });
};

const successHandler = (response, dispatch) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  if (response.status === 200) {
    return response.data;
  }
  throw response.data;
};

export const setResponseInterceptor = dispatch => {
  api.interceptors.response.use(
    response => successHandler(response, dispatch),
    error => errorHandler(error, dispatch),
  );
};

export const withAuthConfig = {
  withCredentials: true,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
  },
};

export default api;
