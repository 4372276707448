/**
 *
 * Asynchronously loads the component for CreateContact
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreateContact = lazyLoad(
  () => import('./index'),
  module => module.CreateContact,
);
