import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { singleContactSaga } from './saga';
import { SingleContactState } from './types';

export const initialState: SingleContactState = {
  isFetchingContact: false,
  contact: null,
  isUpdatingContact: false,
  contactError: null,
  contactAgreements: [],
  fields: {
    name: '',
    email: '',
    phone: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
  isUpdatingSingleContact: false,
};

const slice = createSlice({
  name: 'singleContact',
  initialState,
  reducers: {
    resetForm(state) {
      state.fields = initialState.fields;
    },
    resetContact(state) {
      state.contact = initialState.contact;
      state.contactAgreements = initialState.contactAgreements;
    },
    changeFieldValue(state, action: PayloadAction<any>) {
      state.fields[action.payload.fieldName] = action.payload.value;
    },
    getSingleContact(state, action: PayloadAction<any>) {
      state.isFetchingContact = true;
      state.contactError = null;
    },
    getSingleContactSuccess(state, action: PayloadAction<any>) {
      state.isFetchingContact = false;
      state.contact = action.payload;
      state.fields['name'] = action.payload.name || action.payload.companyName;
      state.fields.email = action.payload.email || action.payload.noticeEmail;
      state.fields.phone = action.payload.phone;
      state.fields = {
        ...state.fields,
        ...action.payload.legalAddress,
      };
      state.contactAgreements = action.payload.agreements || [];
    },
    getSingleContactError(state, action: PayloadAction<any>) {
      state.isFetchingContact = false;
      state.contactError = action.payload.message;
    },
    updateSingleContact(state, action: PayloadAction<any>) {
      state.isUpdatingContact = true;
      state.contactError = null;
    },
    updateSingleContactSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;
    },
    updateSingleContactError(state, action: PayloadAction<any>) {
      state.isUpdatingContact = false;
      state.contactError = action.payload.message;
    },

    updateContactPOCList(state, action: PayloadAction<any>) {
      if (state.contact) state.contact.companySigners = action.payload;
    },

    updateContactUserPropertiesList(state, action: PayloadAction<any>) {
      if (state.contact) state.contact.userProperties = action.payload;
    },
  },
});

export const { actions: singleContactActions } = slice;

export const useSingleContactSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: singleContactSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSingleContactSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
