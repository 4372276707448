/**
 *
 * Asynchronously loads the component for IndividualDocViewer
 *
 */

import { lazyLoad } from 'utils/loadable';

export const IndividualDocViewer = lazyLoad(
  () => import('./index'),
  module => module.IndividualDocViewer,
);
