import { call, put, takeLatest } from 'redux-saga/effects';
import { newAccountActions as actions } from '.';
import api from 'utils/api';
import { urls } from 'utils/apiUrls';
import { toast } from 'react-toast';

function* doClearEmailExistsError(){
  actions.onClearEmailAlreadyExists()
}

function* doRegister(data) {
  try {
    const res = yield call(api.post, urls.register, data.payload.req);

    yield put(actions.onRegisterSuccess(res));
    if (data.payload.successCb) {
      data.payload.successCb(res);
    }
  } catch (error) {
    console.error(error);
    toast.error(error?.message || 'Something went wrong');
    yield put(actions.onRegisterFail(error || 'Something went wrong'));
  }
}

export function* newAccountSaga() {
  yield takeLatest(actions.onRegister.type, doRegister);
}
